import { useEffect, useState } from 'react';
import PageLoading from '../../components/PageLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientOriginationConfig } from '../../apis/seller';
import { useAppDispatch } from '../../store/reducer/Hooks';
import { setSellerOriginationConfig } from '../../store/reducer/SellerReducer';
import getInvoiceUuid from '../UnsolicitedPayment/getInvoiceUuid';
import ReferenceDetails from '../ReferenceDetails';
import { setReference } from '../../store/reducer/UnsolicitedPaymentReducer';

enum Step {
    LOADING,
    REFERENCE_DETAILS,
}

export default function LoadInvoiceFromReference() {
    const { reference } = useParams<{ reference: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(Step.LOADING);

    useEffect(() => {
        load();
    }, [reference]);

    const load = async () => {
        if (reference) {
            dispatch(setReference(reference));
            const invoiceUuid = await getInvoiceUuid(reference);
            if (invoiceUuid) {
                navigate(`/${invoiceUuid}`);
                return;
            }

            try {
                const config = await getClientOriginationConfig();
                dispatch(setSellerOriginationConfig(config));
                navigate('/client-payment/payment-details');
            } catch {
                dispatch(setSellerOriginationConfig(undefined));
                setStep(Step.REFERENCE_DETAILS);
            }
        }
    };

    if (step === Step.LOADING) {
        return <PageLoading />;
    }

    if (step === Step.REFERENCE_DETAILS) {
        return <ReferenceDetails noInvoiceFromUrlReference />;
    }

    return null;
}
