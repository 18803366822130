import { Divider, Grid, Typography } from '@mui/material';
import { Policy } from '../type/Types';
import moment from 'moment/moment';
import { DATE_DISPLAY_FORMAT } from '../functions/instalmentUtils';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

interface Props {
    policy: Policy;
    compact?: boolean;
}

const PolicySummary = (props: Props) => {
    const { policy } = props;

    return (
        <Grid container sx={{ mt: props.compact ? 0 : 3 }}>
            <Grid item xs={8}>
                <Typography sx={{ fontWeight: 600 }}>{policy.classCode}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                <Typography sx={{ fontWeight: 600 }}>
                    {currencyFormatter.format(policy.currentPolicyVersion.premiums)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px' }}>#{policy.number}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px' }}>{policy.provider}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px' }}>
                    {moment(policy.startDate).format(DATE_DISPLAY_FORMAT)} -{' '}
                    {moment(policy.endDate).format(DATE_DISPLAY_FORMAT)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};

export default PolicySummary;
