import moment from 'moment/moment';
import { InvoicePaymentMethod } from '../store/reducer/SelectedPaymentMethodReducer';
import { PaymentRequestBase } from '../apis/payment';
import { PaymentFormFields } from '../pages/SimplifiedPayment';

export const buildPaymentRequest = (data: PaymentFormFields): ((token?: string) => PaymentRequestBase) => {
    const paymentFrequency = data.termSelect;
    const firstPaymentDate = moment(data.paymentStartDate).format('YYYY-MM-DD');

    if (data.paymentMethod === InvoicePaymentMethod.CREDIT_CARD) {
        return (authToken) => {
            return {
                paymentDetail: {
                    paymentFrequency,
                    firstPaymentDate,
                    paymentMethodType: InvoicePaymentMethod.CREDIT_CARD,
                },
                type: InvoicePaymentMethod.CREDIT_CARD,
                authToken: authToken,
            };
        };
    } else {
        return () => {
            return {
                paymentDetail: {
                    paymentFrequency,
                    firstPaymentDate,
                    paymentMethodType: InvoicePaymentMethod.DIRECT_DEBIT,
                },
                type: InvoicePaymentMethod.DIRECT_DEBIT,
                bankAccount: {
                    accountHolder: data.accountHolder,
                    bankAccountNumber: data.bankAccountNumber,
                    confirmAuthority: data.confirmAuthority,
                },
            };
        };
    }
};

export default buildPaymentRequest;
