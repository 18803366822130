import { ArrowForwardIos } from '@mui/icons-material';
import { Alert, Box, Button, capitalize, Card, CardActionArea, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckoutStatus, CheckoutStatusDto, getCheckoutStatus } from '../../apis/checkoutFlowStatus';
import {
    CheckoutType,
    CustomCheckoutSelectTermResponse,
    CustomCheckoutType,
    externalPaymentOptionNotAvaliable,
    fetchTerms,
    PaymentFrequency,
    SelectTermResponse,
    setTerm,
    Term,
} from '../../apis/term';
import { FetchWrapper } from '../../components/FetchWrapper';
import PageLoading from '../../components/PageLoading';
import PaymentOptionExtraInfo from '../../components/PaymentOptionExtraInfo';
import { useFetch } from '../../hooks/useFetch';
import { useAppDispatch } from '../../store/reducer/Hooks';
import { setSelectedTerm } from '../../store/reducer/SelectedTermReducer';
import { RootState } from '../../store/Store';
import styles from '../../style/app.module.css';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

const PaymentOptionCardActionArea = styled(CardActionArea)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    minHeight: '90px',
    width: '100%',
    textAlign: 'left',
}));

const isCustomCheckout = (toBeDetermined?: SelectTermResponse): toBeDetermined is CustomCheckoutSelectTermResponse => {
    return toBeDetermined?.type === CheckoutType.CUSTOM;
};

const customCheckoutProviderDescription: { [key in CustomCheckoutType]?: string } = {
    [CustomCheckoutType.IQUMULATE]: 'IQumulate',
};

const PaymentOptions = () => {
    const [loadingSubmitRes, setLoadingSubmitRes] = useState(false);
    const [selectedCustomCheckout, setSelectedCustomCheckout] = useState<CustomCheckoutType | null>(null);
    const [checkoutProviderUrl, setCheckoutProviderUrl] = useState<string | undefined>(undefined);
    const [notAvailableTerms, setNotAvailableTerms] = useState<{ [key: string]: string | undefined }>({});

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const invoice = useSelector((state: RootState) => state.persistedInvoiceReducer).value;
    const termsState = useFetch(() => fetchTerms(invoice), [invoice], { canFetch: () => invoice != null });

    const selectTerm = (selectedTerm: string, customCheckoutType: CustomCheckoutType) => {
        setLoadingSubmitRes(true);
        setNotAvailableTerms({ ...notAvailableTerms, [selectedTerm]: undefined });
        setTerm(invoice.uuid, selectedTerm)
            .then((selectTermResponse) => {
                if (isCustomCheckout(selectTermResponse)) {
                    window.open(selectTermResponse.providerUrl, '_blank');
                    setSelectedCustomCheckout(selectTermResponse.term.customCheckoutType);
                    setCheckoutProviderUrl(selectTermResponse.providerUrl);
                } else {
                    dispatch(setSelectedTerm(selectTermResponse.term));
                    if (selectTermResponse.term.paymentFrequency === PaymentFrequency.IN_FULL) {
                        navigate('/payment', { state: selectTermResponse.term });
                    } else {
                        return getCheckoutStatus(invoice.uuid).then((checkoutStatus: CheckoutStatusDto) => {
                            if (checkoutStatus.checkoutStatus === CheckoutStatus.ID_CREATE_ACCOUNT) {
                                navigate('/create-account');
                            } else {
                                navigate('/summary');
                            }
                        });
                    }
                }
            })
            .catch((errorMessage) => {
                let translatedErrorMessage;
                if (errorMessage === externalPaymentOptionNotAvaliable) {
                    const externalProviderName =
                        customCheckoutType === CustomCheckoutType.IQUMULATE
                            ? 'IQumulate'
                            : capitalize(customCheckoutType.toLowerCase());
                    translatedErrorMessage = `The ${externalProviderName} payment option is not available. Please contact ${externalProviderName} or your broker for help.`;
                } else {
                    translatedErrorMessage = errorMessage;
                }

                setNotAvailableTerms({ ...notAvailableTerms, [selectedTerm]: translatedErrorMessage });
            })
            .finally(() => setLoadingSubmitRes(false));
    };

    const describeTerm = (paymentFrequency: PaymentFrequency): JSX.Element => {
        if (paymentFrequency === PaymentFrequency.IN_FULL) {
            return (
                <Typography variant='h1' component='p' sx={{ pl: 1 }}>
                    Pay in full
                </Typography>
            );
        }
        return (
            <Typography variant='h1' component='p' sx={{ textTransform: 'lowercase', pl: 1 }}>
                / {paymentFrequency}
            </Typography>
        );
    };

    const Terms = useCallback(
        ({ data }: { data: Term[] }) => {
            if (selectedCustomCheckout && checkoutProviderUrl) {
                return genericCustomCheckout(selectedCustomCheckout, checkoutProviderUrl, setSelectedCustomCheckout);
            }

            return (
                <>
                    {chooseTermHeader(data)}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
                        {data.map((term) => (
                            <Card
                                key={term.uuid}
                                onClick={() => selectTerm(term.uuid, term.customCheckoutType)}
                                sx={{ p: 0 }}
                            >
                                <PaymentOptionCardActionArea data-testid={`paymentOption${term.paymentFrequency}`}>
                                    <Box>
                                        <Box className={styles.sim_repayment_box_outer}>
                                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                <Typography variant='h3' component='p'>
                                                    {currencyFormatter.format(term.instalmentAmount)}
                                                </Typography>
                                                {describeTerm(term.paymentFrequency)}
                                                <Typography variant='h1' component='p'>
                                                    *
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <PaymentOptionExtraInfo customCheckoutType={term.customCheckoutType} />
                                        {notAvailableTerms[term.uuid] && (
                                            <Alert severity='error' sx={{ mt: 3 }}>
                                                {notAvailableTerms[term.uuid]}
                                            </Alert>
                                        )}
                                    </Box>
                                    <ArrowForwardIos color='primary' />
                                </PaymentOptionCardActionArea>
                            </Card>
                        ))}
                    </Box>
                </>
            );
        },
        [selectedCustomCheckout, checkoutProviderUrl, notAvailableTerms]
    );

    return loadingSubmitRes ? <PageLoading /> : <FetchWrapper state={termsState} SuccessComponent={Terms} />;
};

const chooseTermHeader = (terms: Term[]) => {
    if (terms.length === 0) {
        return (
            <Typography variant='h2'>
                Unfortunately you cannot use Simfuni to pay for your insurance at this time. Please contact your broker
                for more details.
            </Typography>
        );
    }
    if (terms.length === 1) {
        return <Typography variant='h2'>Please click the button below to acknowledge your payment term:</Typography>;
    }
    return (
        <>
            <Typography variant='h2'>How do you want to pay?</Typography>
            <Typography variant='body1'>Select which payment option works for you best before proceeding.</Typography>
            <Typography variant='subtitle1' component='p'>
                * Additional setup and processing fees apply
            </Typography>
        </>
    );
};

const genericCustomCheckout = (
    selectedCustomCheckout: CustomCheckoutType,
    checkoutProviderUrl: string,
    setSelectedCustomCheckout: (_val: CustomCheckoutType | null) => void
) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
        <Typography variant='h2' component='h1'>
            Application with {customCheckoutProviderDescription[selectedCustomCheckout]} in progress...
        </Typography>
        <Typography>
            Don't see the {customCheckoutProviderDescription[selectedCustomCheckout]} window?
            <Link sx={{ pl: 1 }} href={checkoutProviderUrl} target='_blank'>
                Relaunch
            </Link>
        </Typography>
        <Typography>Or</Typography>
        <Button onClick={() => setSelectedCustomCheckout(null)} variant='contained'>
            Back to payment options
        </Button>
    </Box>
);

export default PaymentOptions;
