import { CircularProgress, Typography } from '@mui/material';

import { styled } from '@mui/system';

const SimfuniCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary?.dark,
}));

const PageLoading = () => {
    return (
        <Typography data-testid='loading' style={{ textAlign: 'center', paddingTop: '20px' }}>
            <SimfuniCircularProgress size={40} />
        </Typography>
    );
};

export default PageLoading;
