import { useState } from 'react';
import { AccountBalance } from '@mui/icons-material';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { BankAccount as BankAccountDataType } from '../apis/directDebitAuthority';
import { AxiosResponse } from 'axios';
import { Term } from '../apis/term';
import { LoadingButton } from './LoadingButton';
import { FeeConfig } from '../functions/feeUtils';
import { BankAccountFormFields } from '../pages/SimplifiedPayment';
import BankAccount from './BankAccount';

const schema = yup.object({
    accountHolder: yup.string().trim().required('Account holder name required'),
    bankAccountNumber: yup
        .string()
        .trim()
        .required('Bank account number required')
        .matches(/^\d{2}-\d{4}-\d{7}-\d{2,3}$/, 'Bank account number format is invalid'),
    confirmAuthority: yup.boolean().oneOf([true]).required('Confirmation of authority over bank account is required'),
});

export interface BankAccountProps {
    term: Term;
    onSubmit: (bankaccount: BankAccountDataType) => Promise<AxiosResponse>;
    feeConfig: FeeConfig;
}

const BankAccountForm = (props: BankAccountProps) => {
    const { term, onSubmit, feeConfig } = props;
    const [loadingSubmitRes, setLoadingSubmitRes] = useState(false);

    const {
        handleSubmit,
        setError,
        clearErrors,
        control,
        formState: { errors },
    } = useForm<BankAccountFormFields>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const submitHandler: SubmitHandler<BankAccountFormFields> = (data) => {
        setLoadingSubmitRes(true);
        onSubmit(data)
            .catch((e) => {
                if (e.response && e.response.status === 400) {
                    setError('bankAccountNumber', { message: 'Invalid bank/branch' });
                }
            })
            .finally(() => setLoadingSubmitRes(false));
    };

    return (
        <form autoComplete='off' onSubmit={handleSubmit(submitHandler)}>
            <BankAccount
                directDebitAuthorityNumber={term.termPaymentMethod?.directDebitAuthorityNumber}
                paymentFrequency={term.paymentFrequency}
                paymentAmount={term.initialPaymentAmount}
                regularInstalmentAmount={term.instalmentAmount + feeConfig.instalmentPaymentFee}
                control={control}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
            />
            <LoadingButton
                loading={loadingSubmitRes}
                type='submit'
                variant='contained'
                fullWidth
                sx={{ mt: 2 }}
                endIcon={<AccountBalance />}
                data-testid='submitPayment'
            >
                Complete Payment
            </LoadingButton>
        </form>
    );
};

export default BankAccountForm;
