import { Paper } from '@mui/material';

import { FetchWrapper } from '../../components/FetchWrapper';
import { useFetch } from '../../hooks/useFetch';
import { fetchLoanContract, LoanContractDetail } from '../../apis/loanContract';
import { useParams, useSearchParams } from 'react-router-dom';
import { DATE_SERVER_FORMAT } from '../../functions/instalmentUtils';
import moment from 'moment/moment';

const loanContractDetail = ({ data }: { data: LoanContractDetail }) => (
    <Paper className='loan-contract' dangerouslySetInnerHTML={{ __html: data.html }}></Paper>
);

const LoanContract = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get('startDate') ?? moment().format(DATE_SERVER_FORMAT);

    const fetchLoanContractDetail = useFetch(() => fetchLoanContract(uuid as string, startDate), [uuid], {
        canFetch: () => uuid != null,
    });

    return <FetchWrapper state={fetchLoanContractDetail} SuccessComponent={loanContractDetail} />;
};

export default LoanContract;
