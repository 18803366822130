import { Charge, PaymentFrequency, TermPaymentConfig } from './term';
import { Portfolio } from '../type/Types';
import axios from 'axios';
import { BankAccount } from './directDebitAuthority';
import { InvoicePaymentMethod } from '../store/reducer/SelectedPaymentMethodReducer';

export type PaymentConfig = {
    termPaymentConfig: TermPaymentConfig[];
    portfolio: Portfolio;
};

export type CheckoutPaymentConfig = {
    paymentConfig: PaymentConfig;
};

export type PaymentDetail = {
    paymentFrequency: PaymentFrequency;
    firstPaymentDate: string;
    paymentMethodType: InvoicePaymentMethod;
};

export type PaymentRequestBase = {
    paymentDetail: PaymentDetail;
    type: InvoicePaymentMethod;
};

export type CardPaymentRequest = PaymentRequestBase & {
    authToken: string;
};

export type DirectDebitPaymentRequest = PaymentRequestBase & {
    bankAccount: BankAccount;
};

export type PaymentResponse = {
    status: PaymentStatus;
    sessionUrl?: string;
};

export enum PaymentMethodStatus {
    CREATED = 'CREATED',
    VOID = 'VOID',
    VERIFIED = 'VERIFIED',
}

type BasePaymentMethod = {
    uuid: string;
    type: InvoicePaymentMethod;
    accountIdentifier: string;
    isActive: boolean;
    status: PaymentMethodStatus;
};

export type CardPaymentMethod = BasePaymentMethod & {
    cardType: number;
    maskedCardNumber: string;
};

export type BankAccountPaymentMethod = BasePaymentMethod & {
    accountHolder: number;
    bankAccountNumber: string;
};

export type PaymentSummary = {
    paymentFrequency: PaymentFrequency;
    firstPaymentDate: string;
    finalPaymentDate: string;
    firstPaymentAmount: number;
    instalmentPaymentAmount: number;
    paymentMethodType: InvoicePaymentMethod;
    paymentMethodSignature: string;
    fees: Charge[];
    numberOfPayments: number;
    interestAmount: number;
    interestRate: number;
};

export enum PaymentStatus {
    SUCCEED = 'SUCCEED',
    PROCESSING = 'PROCESSING',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    INITIATED = 'INITIATED',
    READY_FOR_BILLING = 'READY_FOR_BILLING',
    ERROR = 'ERROR',
}

export const fetchCheckoutPaymentConfig = async (
    invoiceIdentifier: string,
    firstPaymentDate: string
): Promise<CheckoutPaymentConfig> => {
    return await axios
        .get(
            `${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceIdentifier}/config?firstPaymentDate=${firstPaymentDate}`
        )
        .then(({ data }) => data);
};

export const fetchCheckoutPaymentTermAdjustment = async (
    invoiceIdentifier: string,
    firstPaymentDate: string
): Promise<PaymentConfig> => {
    return await axios
        .get(
            `${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceIdentifier}/config/_query?firstPaymentDate=${firstPaymentDate}`
        )
        .then(({ data }) => data);
};

export const submitPayment = async (
    invoiceIdentifier: string,
    paymentRequest: PaymentRequestBase
): Promise<PaymentResponse> => {
    return await axios
        .post(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceIdentifier}/payment`, paymentRequest)
        .then(({ data }) => data);
};

export const fetchPaymentSummary = async (invoiceIdentifier: string): Promise<PaymentSummary> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceIdentifier}/payment/summary`)
        .then(({ data }) => data);
};

type WindcaveTokenCompleteResponse = {
    paymentMethodIdentifier: string;
};

export const completeTokenSession = async (sessionId: string): Promise<WindcaveTokenCompleteResponse> => {
    return await axios
        .post(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/token/session/complete`, null, {
            params: { sessionId },
        })
        .then(({ data }) => data);
};
