import { CheckoutStatus } from '../../apis/checkoutFlowStatus';
import LayoutWrapper from '../../components/LayoutWrapper';
import StatusGuard from '../../components/StatusGuard';
import Invoice from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper>
            <StatusGuard status={CheckoutStatus.SELECT_TERM} route={'/invoice'}>
                <Invoice />
            </StatusGuard>
        </LayoutWrapper>
    );
}
