import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { nonRedirectingAxios } from './util';

const axios = nonRedirectingAxios();
const addyKey = process.env.REACT_APP_ADDY_KEY;
const config = {
    headers: {
        'addy-api-key': addyKey,
    } as AxiosRequestHeaders,
} as AxiosRequestConfig;
const addUrl = 'https://api-nz.addysolutions.com';

export type MatchResponse = {
    address1?: string;
    address2?: string;
    full?: string;
    suburb?: string;
    city?: string;
    postcode?: string;
};

export const fetchAddressRequestPromise = async (id: number): Promise<MatchResponse> => {
    return await axios.get(`${addUrl}/address/${id}`, config).then((response) => response.data);
};

export type MatchedAddresses = {
    matched: number;
    addresses: AddressMatch[];
};

type AddressMatch = {
    id: number;
    a: string;
};

export const fetchAddressesRequestPromise = async (query: string): Promise<MatchedAddresses> => {
    return await axios.get(`${addUrl}/search/?max=5&s=${query}`, config).then((response) => response.data);
};
