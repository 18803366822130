import { InvoiceSummary } from '../type/Types';
import { Address, InsuredType } from './checkout';
import { nonRedirectingAxios } from './util';

export type ClientLead = {
    insuredType?: InsuredType;
    emailAddress: string;
    mobileNumber?: string;
    name?: string;
    address: Address;
    reference: string;
};

export type ClientLeadRequest = {
    insuredType?: InsuredType;
    emailAddress: string;
    mobileNumber?: string;
    name?: string;
    address?: Address;
    reference: string;
};

export type ClientLeadRequestWrapper = {
    clientLead: ClientLeadRequest;
    invoiceAmount: number;
};

export const createClientLead = async (clientLeadRequest: ClientLeadRequestWrapper): Promise<InvoiceSummary> => {
    return await nonRedirectingAxios()
        .post(`${process.env.REACT_APP_CHECKOUT_HOST}/client-lead`, clientLeadRequest)
        .then(({ data }) => data);
};
