import { RouteProps, useNavigate } from 'react-router-dom';
import { FetchStateType, useFetch } from '../hooks/useFetch';
import { CheckoutStatus, CheckoutStatusDto, getCheckoutStatus } from '../apis/checkoutFlowStatus';

import { RootState } from '../store/Store';
import { useAppSelector } from '../store/reducer/Hooks';
import { includes, isEmpty } from 'lodash';
import PageLoading from './PageLoading';
import { postAccountCreated } from '../apis/createAccount';
import { useAuthenticator } from '@aws-amplify/ui-react';

export type StatusGuardProps = RouteProps & {
    children: JSX.Element;
    status: CheckoutStatus;
    authRequired?: boolean;
    route: string;
};

export const getRoute = (currentStatus: CheckoutStatus, requestedRoute: string | undefined) => {
    switch (currentStatus) {
        case CheckoutStatus.SELECT_TERM:
            if (requestedRoute === '/invoice') {
                return '/invoice';
            } else {
                return '/payment-options';
            }

        case CheckoutStatus.ID_CREATE_ACCOUNT:
            return '/create-account';

        case CheckoutStatus.CONFIRM_LOAN_DETAILS:
            return '/summary';

        case CheckoutStatus.PAYMENT:
            if (requestedRoute === '/payment') {
                return '/payment';
            } else {
                return '/payment-processing';
            }

        case CheckoutStatus.COMPLETE:
            return '/payment-complete';
    }
};

const isAllowedToAccess = (
    statusForPage: CheckoutStatus,
    authReqForPage: boolean | undefined,
    statusFromServer: CheckoutStatusDto
) => {
    if (authReqForPage && authReqForPage !== statusFromServer.authRequired) {
        return false;
    }
    if (statusForPage === statusFromServer.checkoutStatus) {
        return true;
    }
    if (includes(statusFromServer.backwardsPaths, statusForPage)) {
        return true;
    }
};

const StatusGuard = (props: StatusGuardProps) => {
    const navigate = useNavigate();
    const invoiceUuid: string = useAppSelector((state: RootState) => state.persistedInvoiceReducer).uuid;
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    const state = useFetch(
        () => {
            return getCheckoutStatus(invoiceUuid).then(async (res) => {
                // if the status is stuck on CREATE_ACCOUNT but there's a session because a log in occurred, bump the status on
                if (res.checkoutStatus === CheckoutStatus.ID_CREATE_ACCOUNT && authStatus === 'authenticated') {
                    res = await postAccountCreated(invoiceUuid).catch();
                }

                if (!isAllowedToAccess(props.status, props.authRequired, res)) {
                    const redirectedRoute = getRoute(res.checkoutStatus, props.route);
                    if (!redirectedRoute) {
                        navigate('/not-found');
                        return null;
                    }

                    navigate(redirectedRoute);
                    return null;
                }

                return res;
            });
        },
        [invoiceUuid, props.status],
        { canFetch: () => !!invoiceUuid }
    );

    if (isEmpty(invoiceUuid)) {
        navigate('/not-found');
        return <div data-testid='redirecting' />;
    }

    if (state.type !== FetchStateType.SUCCESS) {
        return <PageLoading />;
    }

    if (!state.value) {
        return <div data-testid='redirecting' />;
    }

    return <>{props.children}</>;
};

export default StatusGuard;
