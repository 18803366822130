import { useEffect, useImperativeHandle, useState } from 'react';
import { Alert, Box, FormControl, FormHelperText, FormLabel, Grid, TextField, Typography } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { Control, Controller, FieldErrors, UseFormGetValues } from 'react-hook-form';
import { grey } from '@mui/material/colors';
import { PaymentFrequency } from '../apis/term';
import { CYBERSOURCE_STATE, setCybersourceState } from '../store/reducer/CybersourceReducer';
import { useAppDispatch, useAppSelector } from '../store/reducer/Hooks';
import { RootState } from '../store/Store';
import { FetchStateType, useFetch } from '../hooks/useFetch';
import { fetchPaymentSession } from '../apis/checkout';
import { CybersourceFormFields } from '../pages/SimplifiedPayment';
import PageLoading from './PageLoading';

interface Props<T extends CybersourceFormFields> {
    paymentFrequency?: PaymentFrequency;
    regularInstalmentAmount?: number;
    control: Control<T>;
    errors: FieldErrors<T>;
    getValues: UseFormGetValues<T>;
    preSubmitRef: React.MutableRefObject<{ preSubmitHelper: () => Promise<string> } | undefined>;
    cardPaymentError?: string;
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

const CybersourcePayment = <T extends CybersourceFormFields>(props: Props<T>) => {
    // hack to properly cast props as react-hook-form cannot handle generics
    const typeCastProps = props as unknown as Props<CybersourceFormFields>;

    const { paymentFrequency, regularInstalmentAmount, cardPaymentError, control, errors, getValues, preSubmitRef } =
        typeCastProps;

    const [microForm, setMicroForm] = useState();
    const [cardNumberError, setCardNumberError] = useState<string | null>(null);
    const [securityCodeError, setSecurityCodeError] = useState<string | null>(null);
    const [formError, setFormError] = useState<string | null>(null);

    const cybersourceState: CYBERSOURCE_STATE = useAppSelector((state: RootState) => state.CybersourceReducer).value;
    const invoiceUuid = useAppSelector((state: RootState) => state.persistedInvoiceReducer).uuid;
    const integratedView = useAppSelector((state: RootState) => state.persistedSellerReducer).integratedView;
    const [loadingCybersourceInputs, setLoadingCybersourceInputs] = useState(true);
    const dispatch = useAppDispatch();
    useImperativeHandle(preSubmitRef, () => ({ preSubmitHelper }));

    const sessionState = useFetch(() => fetchPaymentSession(invoiceUuid, integratedView));

    useEffect(() => {
        if (cybersourceState === CYBERSOURCE_STATE.PRE_LOAD) {
            addCybersourceScript();
        } else if (cybersourceState === CYBERSOURCE_STATE.LOADED && sessionState.type === FetchStateType.SUCCESS) {
            setupMicroform(sessionState.value);
        }
    }, [cybersourceState, sessionState]);

    useEffect(() => {
        if (cardPaymentError) {
            setFormError(cardPaymentError);
        }
    }, [cardPaymentError]);

    const addCybersourceScript = () => {
        setLoadingCybersourceInputs(true);
        dispatch(setCybersourceState(CYBERSOURCE_STATE.LOADING));

        const s = document.createElement('script');
        s.src = 'https://flex.cybersource.com/microform/bundle/v1/flex-microform.min.js';
        s.type = 'text/javascript';
        s.async = false;
        s.onload = () => dispatch(setCybersourceState(CYBERSOURCE_STATE.LOADED));
        document.body.appendChild(s);
    };

    const setupMicroform = (paymentSession: string) => {
        const styles = {
            input: {
                'font-size': '14px',
                'font-family': 'helvetica, tahoma, calibri, sans-serif',
                color: grey['500'],
            },
            ':disabled': { cursor: 'not-allowed' },
        };
        // @ts-ignore
        const flex = new window.Flex(paymentSession);
        const microformSetup = flex.microform({ styles: styles });

        // @ts-ignore
        const number = microformSetup.createField('number', {});
        number.load('#number-container');
        // @ts-ignore
        number.on('change', (data) => {
            if (data.empty) {
                setCardNumberError('Card number required');
                return;
            }

            if (!data.valid && !data.couldBeValid) {
                setCardNumberError('Invalid card number');
                return;
            } else {
                setCardNumberError(null);
            }

            if (data.card[0]) {
                securityCode.update({ disabled: false, placeholder: data.card[0].securityCode.name });
            }
        });

        // @ts-ignore
        const securityCode = microformSetup.createField('securityCode', { placeholder: '***' });
        securityCode.load('#securityCode-container');

        // @ts-ignore
        securityCode.on('change', (data) => {
            if (!data.valid && !data.couldBeValid) {
                setSecurityCodeError('Invalid code');
            } else {
                setSecurityCodeError(null);
            }

            if (data.empty) {
                setSecurityCodeError('Code required');
            }
        });

        setMicroForm(microformSetup);
        setLoadingCybersourceInputs(false);
    };

    const preSubmitHelper = (): Promise<string> => {
        const expiry = getValues('expiry');
        setFormError(null);

        const options = {
            expirationMonth: expiry.split('/')[0],
            expirationYear: '20' + expiry.split('/')[1],
        };

        return new Promise((resolve, reject) => {
            console.log('creating microform token');
            // @ts-ignore
            microForm.createToken(options, (err, token) => {
                if (err) {
                    setFormError(err.message);
                    err.details?.forEach((detail: { location: string }) => {
                        if (detail.location === 'number') {
                            setCardNumberError('Please enter a valid card number');
                        } else if (detail.location === 'securityCode') {
                            setSecurityCodeError('Please enter a valid security code');
                        }
                    });
                    return reject(new Error('Invalid form data'));
                } else {
                    return resolve(JSON.stringify(token));
                }
            });
        });
    };

    return (
        <>
            {formError && (
                <Alert severity='error' sx={{ mb: 2 }}>
                    {formError}
                </Alert>
            )}

            {loadingCybersourceInputs && <PageLoading />}

            <Box
                sx={{
                    height: loadingCybersourceInputs ? 0 : '100%',
                    visibility: loadingCybersourceInputs ? 'hidden' : 'visible',
                }}
            >
                <FormControl required fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Card number</FormLabel>
                    <div className={cardNumberError != null ? 'registered-error' : ''}>
                        <div id='number-container' className='form-control'></div>
                    </div>
                    {cardNumberError != null && <FormHelperText error>{cardNumberError}</FormHelperText>}
                </FormControl>

                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <FormControl required fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Expires</FormLabel>
                            <Controller
                                name='expiry'
                                control={control}
                                defaultValue={''}
                                render={({ field }) => {
                                    return (
                                        <ReactInputMask
                                            mask='99/99'
                                            alwaysShowMask={true}
                                            maskPlaceholder='MM/YY'
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                        >
                                            <TextField
                                                {...field}
                                                error={errors.expiry !== undefined}
                                                helperText={errors.expiry?.message}
                                                size='small'
                                            />
                                        </ReactInputMask>
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl required fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Security Code</FormLabel>
                            <div className={securityCodeError != null ? 'registered-error' : ''}>
                                <div id='securityCode-container' className='form-control'></div>
                            </div>
                            {securityCodeError != null && <FormHelperText error>{securityCodeError}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            {paymentFrequency && regularInstalmentAmount && paymentFrequency !== PaymentFrequency.IN_FULL && (
                <Typography sx={{ mt: 1, mb: 2 }}>
                    Your regular instalment amount is{' '}
                    <span style={{ display: 'inline', fontWeight: 600, fontSize: 18 }} data-testid='regularInstalment'>
                        {currencyFormatter.format(regularInstalmentAmount)}
                    </span>
                    <span style={{ display: 'inline', fontSize: 11 }}> (includes processing fee).</span>
                </Typography>
            )}

            <input type='hidden' id='flexresponse' name='flexresponse' />
        </>
    );
};

export default CybersourcePayment;
