import { useEffect } from 'react';
import { Charge, ChargeType, TermPaymentConfig, TermPaymentMethod } from '../apis/term';
import { InvoicePaymentMethod } from '../store/reducer/SelectedPaymentMethodReducer';
import { CheckoutPaymentConfig } from '../apis/payment';
import { find, union } from 'lodash';
import { getFeeConfig } from '../functions/feeUtils';
import { PaymentPlanAction, setPaymentPlan } from '../store/reducer/PaymentPlanReducer';
import { Dispatch } from 'redux';
import { PaymentFormFields } from '../pages/StandardPayment';
import { Control, useWatch } from 'react-hook-form';

const usePaymentPlan = ({
    control,
    checkoutPaymentConfig,
    setTerm,
    setRelevantTransactionFees,
    setUpfrontPaymentInclFees,
    setAllowedPaymentMethods,
    dispatch,
}: Props) => {
    const paymentFrequency = useWatch({ control, name: 'termSelect' });
    const firstPaymentDate = useWatch({ control, name: 'paymentStartDate' });
    const paymentMethod = useWatch({ control, name: 'paymentMethod' });

    useEffect(() => {
        const selectedTerm = find(
            checkoutPaymentConfig?.paymentConfig.termPaymentConfig,
            (termPaymentConfig) => termPaymentConfig.paymentFrequency === paymentFrequency
        );
        const resolvedPaymentMethod = find(
            selectedTerm?.paymentMethods,
            (termPaymentMethod) => termPaymentMethod.paymentMethodType === paymentMethod
        );
        if (selectedTerm) {
            setTerm(selectedTerm);
            setAllowedPaymentMethods(
                selectedTerm.paymentMethods.filter((paymentMethod) =>
                    Object.values(InvoicePaymentMethod).includes(paymentMethod.paymentMethodType)
                )
            );
        }
        if (resolvedPaymentMethod) {
            setRelevantTransactionFees(resolvedPaymentMethod.paymentMethodCharges);
            if (selectedTerm) {
                const initialFees = union(resolvedPaymentMethod.paymentMethodCharges, selectedTerm?.termCharges);
                const feeConfig = getFeeConfig(initialFees || [], [ChargeType.TRANSACTION_FEE]);
                setUpfrontPaymentInclFees(selectedTerm.firstInstalmentAmount + feeConfig.upfrontPaymentFee);
            }
        }
        dispatch(
            setPaymentPlan({
                paymentFrequency: paymentFrequency,
                firstPaymentDate: firstPaymentDate,
                paymentMethodType: paymentMethod,
            })
        );
    }, [paymentMethod, paymentFrequency, firstPaymentDate, checkoutPaymentConfig]);
};

export default usePaymentPlan;

type Props = {
    control: Control<PaymentFormFields>;
    checkoutPaymentConfig: CheckoutPaymentConfig | null;
    setTerm: (term: TermPaymentConfig) => void;
    setRelevantTransactionFees: (charges: Charge[]) => void;
    setUpfrontPaymentInclFees: (total: number) => void;
    setAllowedPaymentMethods: (paymentMethods: TermPaymentMethod[]) => void;
    dispatch: Dispatch<PaymentPlanAction>;
};
