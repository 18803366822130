import { Auth } from 'aws-amplify';
import { AccountDetails } from '../../apis/checkout';
import { internationalise } from '../../functions/phoneNumberUtils';
import SignUpFormFields from './SignUpFormFields';
import { Authenticator } from '@aws-amplify/ui-react';
import PageLoading from '../../components/PageLoading';
import { useCallback } from 'react';
import { SignUpHeader } from '../../components/AuthHeader';

export default ({ data }: { data: AccountDetails }) => {
    const services = {
        async handleSignUp(formData: FormFields) {
            const { password } = formData;
            return Auth.signUp({
                username: data.email,
                password,
                autoSignIn: { enabled: true },
                attributes: {
                    email: data.email,
                    phone_number: internationalise('+64', data.phoneNumber),
                },
            });
        },
    };

    const FormFields = useCallback(() => <SignUpFormFields data={data} />, [data]);
    const components = {
        SignUp: { FormFields },
        Header: SignUpHeader,
    };

    return (
        <Authenticator components={components} services={services} initialState='signUp'>
            {/* Once sign up/sign in event is emitted, this page will redirect so we just need to show a loading indicator */}
            <PageLoading />
        </Authenticator>
    );
};

type FormFields = {
    password: string;
};
