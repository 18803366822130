export type InvoicePayment = {
    paymentAmount: number;
    transactionFee: number;
    total: number;
};

export enum CheckoutProviderType {
    DEFAULT_PF = 'DEFAULT_PF',
    SIMPLE = 'SIMPLE',
    CLIENT_INITIATED = 'CLIENT_INITIATED',
}
