import { PaymentFrequency } from '../apis/term';
import moment from 'moment';
import { replace, upperFirst } from 'lodash';

export const DATE_SERVER_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'Do MMM YYYY';
export const DATE_ERROR_FORMAT = 'DD/MM/YYYY';

export const getInstalmentDescription = (paymentFrequency: PaymentFrequency, firstPaymentDate: string) => {
    // weekly "every Thursday"
    // fortnightly "every second Thursday"
    // monthly "on the 16th every month"
    const firstPayment = moment(firstPaymentDate);

    switch (paymentFrequency) {
        case PaymentFrequency.WEEKLY:
            return `every ${firstPayment.format('dddd')}`;

        case PaymentFrequency.FORTNIGHTLY:
            return `every second ${firstPayment.format('dddd')}`;

        case PaymentFrequency.MONTHLY:
            if (firstPayment.date() > 28) {
                return `on the ${firstPayment.format('Do')} every month *`;
            }
            return `on the ${firstPayment.format('Do')} every month`;
    }
};

export const formatEnumValue = (enumValue: string | undefined): string => {
    return replace(upperFirst(enumValue?.toLowerCase()), '_', ' ');
};
