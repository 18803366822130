import { Typography } from '@mui/material';
import { PaymentFrequency } from '../apis/term';
import moment from 'moment/moment';

interface PaymentHintProps {
    firstPaymentDate: string | undefined;
    paymentFrequency: PaymentFrequency;
}

export const PaymentDayHint = (props: PaymentHintProps) => {
    const dayNumber = moment(props.firstPaymentDate).date();
    if (props.paymentFrequency === PaymentFrequency.MONTHLY && dayNumber > 28) {
        return (
            <Typography variant='subtitle1' sx={{ mt: 3 }}>
                * Payments are processed on last day of the month for months with less than {dayNumber} days
            </Typography>
        );
    }
    return null;
};
export default PaymentDayHint;
