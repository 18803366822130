import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { persistor, Store } from './store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import BreakpointProvider from './providers/BreakpointProvider';
import { Authenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import SellerThemeProvider from './style/SellerThemeProvider';

require('typeface-montserrat');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={Store}>
        <SellerThemeProvider>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <BreakpointProvider>
                        <Authenticator.Provider>
                            <App />
                        </Authenticator.Provider>
                    </BreakpointProvider>
                </BrowserRouter>
            </PersistGate>
        </SellerThemeProvider>
    </Provider>
);
