import axios from 'axios';

export type LoanContractDetail = {
    html: string;
};

export const fetchLoanContract = async (invoiceUuid: string, firstPaymentDate: string): Promise<LoanContractDetail> => {
    const url = `${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceUuid}/loan-contract`;

    const config = {
        headers: {
            'x-first-payment-date': firstPaymentDate,
        },
    };
    return await axios.get(url, config).then(({ data }) => data);
};
