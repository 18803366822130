import { memo, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { CalculatedOn, Charge, ChargeType, PaymentFrequency, TermPaymentConfig } from '../apis/term';
import { FeeConfig, getFeeConfig } from '../functions/feeUtils';
import PaymentPlanDetail from './PaymentPlanDetail';
import { PaymentSummary } from '../apis/payment';
import PaymentDayHint from './PaymentDayHint';
import { styled } from '@mui/system';
import { useAppSelector } from '../store/reducer/Hooks';
import DefaultFeeHint from './DefaultFeeHint';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

interface PortfolioSummaryProps {
    term?: TermPaymentConfig;
    transactionFees: Charge[];
    firstPaymentDate?: string;
}

const PaymentPlanSummary = memo((props: PortfolioSummaryProps) => {
    const persistedTerm = useAppSelector((state) => state.persistedTermReducer).value;
    const { term, firstPaymentDate, transactionFees } = props;
    const [feeConfig, setFeeConfig] = useState<FeeConfig | undefined>(undefined);
    const [paymentSummary, setPaymentSummary] = useState<PaymentSummary | undefined>(undefined);

    const BorderedBoxStyle = styled(Box)(({ theme }) => ({
        border: '1px solid',
        borderColor: theme.palette.black?.['15'],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        minWidth: '300px',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        background: theme.palette.transparentBackGround?.['100'],
    }));

    useEffect(() => {
        const config = getFeeConfig(transactionFees, ChargeType.TRANSACTION_FEE);
        setFeeConfig(config);
    }, [transactionFees]);

    useEffect(() => {
        if (term) {
            setPaymentSummary({
                fees: term.termCharges,
                firstPaymentAmount: term.firstInstalmentAmount,
                instalmentPaymentAmount: term.instalmentAmount,
                firstPaymentDate: firstPaymentDate,
                finalPaymentDate: term.finalPaymentDate,
                paymentFrequency: term.paymentFrequency,
                numberOfPayments: term.numberOfPayments,
                interestAmount: term.interestAmount,
                interestRate: term.interestRate,
            } as PaymentSummary);
        }
    }, [term, firstPaymentDate]);

    const getFeeDescription = (): JSX.Element | null => {
        if (!feeConfig && persistedTerm) {
            return <Typography sx={{ mb: 1, fontSize: '12px' }}>excludes processing fee</Typography>;
        } else if (feeConfig?.charge?.initialAmount) {
            return (
                <Typography sx={{ mb: 1, fontSize: '12px' }}>
                    {feeConfig.charge?.calculatedOn === CalculatedOn.PERCENT
                        ? `Includes ${feeConfig.charge.chargeValue}% transaction fee`
                        : `Includes ${currencyFormatter.format(feeConfig.charge.initialAmount)} transaction fee`}
                </Typography>
            );
        } else {
            return null;
        }
    };

    if (!feeConfig) {
        return null;
    }

    return (
        <BorderedBoxStyle>
            {paymentSummary ? (
                <>
                    <Typography variant='h4' component='h2' sx={{ mb: 1 }}>
                        {paymentSummary.paymentFrequency === PaymentFrequency.IN_FULL
                            ? 'Payment Summary'
                            : 'Instalment details'}
                    </Typography>
                    {getFeeDescription()}

                    <PaymentPlanDetail paymentSummary={paymentSummary} feeConfig={feeConfig} />

                    {firstPaymentDate != null && (
                        <PaymentDayHint
                            firstPaymentDate={firstPaymentDate}
                            paymentFrequency={paymentSummary.paymentFrequency}
                        />
                    )}

                    <DefaultFeeHint termCharges={term?.termCharges} />
                </>
            ) : (
                <Typography>Please select a payment frequency</Typography>
            )}
        </BorderedBoxStyle>
    );
});

export default PaymentPlanSummary;
