import { Box, Button, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

// @ts-ignore
import simfuniLogo from '../Simfuni_symbol.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const back = -1;
const backTwice = -2;

const PageHeader = () => {
    const { integratedView } = useSelector((state: RootState) => state.persistedSellerReducer);

    const location = useLocation();
    const navigate = useNavigate();

    const content = useMemo(() => {
        if (!integratedView) {
            return (
                <>
                    <img src={simfuniLogo} />
                    <Box style={{ display: 'flex', margin: 'auto' }}>
                        <Typography variant='h1'>Your Insurance Payment</Typography>
                    </Box>
                </>
            );
        }

        const goBack = () => {
            // To go back from the invoice page we need to navigate back twice as there is a silent page transition in the middle
            // fetching the invoice details
            if (location.pathname === '/invoice') {
                navigate(backTwice);
            } else {
                navigate(back);
            }
        };

        return (
            <Button onClick={goBack} variant='text' startIcon={<ArrowBack />}>
                Back
            </Button>
        );
    }, [location, integratedView, navigate]);

    return <Box style={{ display: 'flex', maxWidth: '600px', margin: 'auto' }}>{content}</Box>;
};

export default PageHeader;
