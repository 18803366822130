import { useEffect, useMemo, useState } from 'react';
import { Alert, Box, InputLabel, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '../../components/LoadingButton';
import { getClientOriginationConfig } from '../../apis/seller';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setSellerOriginationConfig } from '../../store/reducer/SellerReducer';
import { setReference } from '../../store/reducer/UnsolicitedPaymentReducer';
import getInvoiceUuid from '../UnsolicitedPayment/getInvoiceUuid';

type Props = {
    noInvoiceFromUrlReference?: boolean;
};

const INVOICE_NOT_FOUND = 'Invoice not found';

export default function ReferenceDetails({ noInvoiceFromUrlReference }: Readonly<Props>) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { originationConfig } = useAppSelector((root) => root.persistedSellerReducer);
    const { reference: referenceFromRedux } = useAppSelector((root) => root.persistedUnsolicitedPaymentReducer);

    const [referenceInput, setReferenceInput] = useState<string>('');
    const [inputError, setInputError] = useState<string | null>(null);
    const [responseError, setResponseError] = useState<string | null>(
        noInvoiceFromUrlReference ? INVOICE_NOT_FOUND : null
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (originationConfig == null) {
            getClientOriginationConfig()
                .then((config) => {
                    dispatch(setSellerOriginationConfig(config));
                })
                .catch(() => {
                    // do nothing
                });
        }
    }, []);

    useEffect(() => {
        if (referenceFromRedux) {
            setReferenceInput(referenceFromRedux.toString());
        }
    }, [referenceFromRedux]);

    const findInvoice = async (reference: string) => {
        setLoading(true);
        dispatch(setReference(reference));
        const invoiceUuid = await getInvoiceUuid(reference);
        if (invoiceUuid) {
            navigate(`/${invoiceUuid}`);
            return;
        }

        if (originationConfig != null) {
            navigate('/client-payment/payment-details');
            return;
        }

        setResponseError(INVOICE_NOT_FOUND);
        setLoading(false);
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (validate(referenceInput)) {
            findInvoice(referenceInput!);
        }
    };

    const validate = (ref?: string) => {
        if (!ref) {
            setInputError('Reference required');
            return false;
        }
        setInputError(null);
        return true;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferenceInput(event.target?.value);
        setResponseError(null);
        validate(event.target?.value);
    };

    const helperText = useMemo(() => {
        if (inputError) {
            return inputError;
        }

        if (originationConfig) {
            return "Don't have a reference? Enter a description to help us identify your payment.";
        }
    }, [inputError, originationConfig]);

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Box>
                    <Typography variant='h5' component='h1' mb={1}>
                        Make a premium payment
                    </Typography>
                    <Typography variant='caption'>
                        Paying for your insurance online is quick and easy. Get started by entering your reference. This
                        can usually be found on your invoice.
                    </Typography>
                </Box>
                <Box>
                    <InputLabel sx={{ mb: 1 }} htmlFor='reference' error={inputError != null}>
                        Reference
                    </InputLabel>
                    <TextField
                        id='reference'
                        type='text'
                        value={referenceInput ?? ''}
                        fullWidth
                        onChange={handleChange}
                        error={inputError != null}
                        helperText={helperText}
                    />
                    {responseError && (
                        <Alert sx={{ mt: 2 }} severity='error'>
                            {responseError}
                        </Alert>
                    )}
                </Box>
                <LoadingButton type='submit' loading={loading} onClick={handleSubmit} variant='contained'>
                    Next
                </LoadingButton>
            </Box>
        </form>
    );
}
