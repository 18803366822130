import { createSlice } from '@reduxjs/toolkit';
import { ClientLead, ClientLeadRequestWrapper } from '../../apis/clientLead';
import { Action } from './Hooks';

const initialState: {
    paymentAmount?: number;
    clientLead?: ClientLead;
    reference?: string;
    clientLeadRequest?: ClientLeadRequestWrapper;
} = {
    paymentAmount: undefined,
    clientLead: undefined,
    reference: undefined,
    clientLeadRequest: undefined,
};

export const unsolicitedSlice = createSlice({
    name: 'unsolicitedPayment',
    initialState,
    reducers: {
        setUnsolicitedPaymentAmount: (state, action: Action<number>) => {
            state.paymentAmount = action.payload;
        },
        setClientLead: (state, action: Action<ClientLead>) => {
            state.clientLead = action.payload;
        },
        setReference: (state, action: Action<string>) => {
            state.reference = action.payload;
        },
        setClientLeadRequest: (state, action: Action<ClientLeadRequestWrapper>) => {
            state.clientLeadRequest = action.payload;
        },
    },
});

export const { setReference, setUnsolicitedPaymentAmount, setClientLead, setClientLeadRequest } =
    unsolicitedSlice.actions;

export default unsolicitedSlice.reducer;
