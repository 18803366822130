import { Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { Portfolio } from '../type/Types';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PolicySummary from './PolicySummary';
import { styled } from '@mui/system';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

interface PortfolioSummaryProps {
    portfolio: Portfolio;
    compact?: boolean;
}

const PolicySection = (props: PortfolioSummaryProps) => (
    <>
        {props.portfolio.policies.map((policy) => (
            <Fragment key={policy.uuid}>
                <PolicySummary policy={policy} compact={props.compact} />
            </Fragment>
        ))}
        <Grid container sx={{ mt: props.compact ? 2 : 3, mb: 2 }} alignItems='center'>
            <Grid item xs={8}>
                <Typography sx={{ fontWeight: 600 }}>Total premiums</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                <Typography sx={{ fontWeight: 600 }}>{currencyFormatter.format(props.portfolio.premiums)}</Typography>
            </Grid>
        </Grid>
    </>
);

const PortfolioSummary = (props: PortfolioSummaryProps) => {
    const { portfolio, compact } = props;

    if (compact) {
        return (
            <Box sx={{ mb: 3 }}>
                <Accordion data-testid='portfolioCompact' className='payment-method-accordion' disableGutters={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0, p: 0 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                width: '100%',
                                minHeight: '64px',
                                cursor: 'pointer',
                                paddingLeft: '10px',
                            }}
                        >
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>Policies</Typography>
                            </span>
                            <span style={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                                <Typography>{currencyFormatter.format(portfolio.premiums)}</Typography>
                            </span>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ bgcolor: grey['200'] }} className='portfolio-detail'>
                        <PolicySection portfolio={portfolio} compact />
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    }

    const BorderedBoxStyle = styled(Box)(({ theme }) => ({
        border: '1px solid',
        borderColor: theme.palette.black?.['15'],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        minWidth: '300px',
        maxWidth: '350px',
        width: '100%',
        marginLeft: theme.spacing(5),
        position: 'fixed',
        top: '174px',
        background: theme.palette.transparentBackGround?.['100'],
    }));

    return (
        <BorderedBoxStyle data-testid='portfolioFull'>
            <Typography variant='h4' component='h2'>
                Policies
            </Typography>
            <PolicySection portfolio={portfolio} />
        </BorderedBoxStyle>
    );
};

export default PortfolioSummary;
