import { CalculatedOn, Charge, ChargeType, Term } from '../apis/term';
import { cloneDeep, filter, flatten, includes, reduce, sumBy } from 'lodash';

export type FeeConfig = {
    upfrontPaymentFee: number;
    instalmentPaymentFee: number;
    charge?: Charge;
};

export function getFeeConfig(feeCharges: Charge[], chargeType: ChargeType | ChargeType[]): FeeConfig {
    const filteredFees: Charge[] = filter(feeCharges, (charge: Charge) => includes(flatten([chargeType]), charge.type));

    const charge = reduce(cloneDeep(filteredFees), (squashed, feeCharge) => {
        if (squashed.calculatedOn === CalculatedOn.FIXED || feeCharge.calculatedOn === CalculatedOn.FIXED) {
            squashed.calculatedOn = CalculatedOn.FIXED;
        } else {
            squashed.calculatedOn = CalculatedOn.PERCENT;
            squashed.chargeValue += feeCharge.chargeValue;
        }
        squashed.initialAmount += feeCharge.initialAmount;
        squashed.amount += feeCharge.amount;

        return squashed;
    });

    return {
        upfrontPaymentFee: sumBy(filteredFees, (feeCharge) => feeCharge.initialAmount),
        instalmentPaymentFee: sumBy(filteredFees, (feeCharge) => feeCharge.amount),
        charge:
            charge ||
            ({
                initialAmount: 0,
                amount: 0,
            } as Charge),
    };
}

export const calculateFirstInstalmentLessSetupFee = (term: Term) => {
    const setupFeeCharges = term.feeCharges
        ?.filter((charge: Charge) => charge.type === ChargeType.SETUP_FEE)
        .map((charge) => charge.initialAmount)
        .reduce((accumulator, chargeAmount) => {
            return accumulator + chargeAmount;
        }, 0);
    return term.initialPaymentAmount - setupFeeCharges;
};
