import { useEffect, useMemo, useState } from 'react';
import { Box, FormControl, FormLabel, Typography } from '@mui/material';
import { PaymentFrequency, TermPaymentConfig } from '../apis/term';
import { debounce } from 'lodash';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { PaymentFormFields } from '../pages/SimplifiedPayment';
import moment, { Moment } from 'moment';
import { DATE_ERROR_FORMAT, DATE_SERVER_FORMAT } from '../functions/instalmentUtils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import getMaxDate from '../util/dateUtil';

interface PaymentFrequencyControlProps {
    term: TermPaymentConfig;
    control: Control<PaymentFormFields>;
    queryPaymentConfig: (firstPaymentDate: string) => Promise<void>;
}

const PaymentStartDateControl = (props: PaymentFrequencyControlProps) => {
    const { control, term, queryPaymentConfig } = props;
    const [maxDate, setMaxDate] = useState<string | null>(null);
    const [firstPaymentDateError, setFirstPaymentDateError] = useState<string | null>(null);
    const [editable, setEditable] = useState<boolean>(true);
    const startDate = moment().format(DATE_SERVER_FORMAT);

    const label = useMemo(() => {
        if (term.paymentFrequency === PaymentFrequency.IN_FULL) {
            return 'Make payment on';
        }
        return 'First payment on';
    }, [term]);

    useEffect(() => {
        const now = moment();
        const maxStartDate = getMaxDate([term.latestFirstPaymentDate, now.format(DATE_SERVER_FORMAT)]);
        setMaxDate(maxStartDate.format(DATE_SERVER_FORMAT));
        if (maxStartDate.isSameOrBefore(now, 'days')) {
            setEditable(false);
        }
    }, []);

    const handleFieldChange = (
        newValue: Moment | null,
        field: ControllerRenderProps<PaymentFormFields, 'paymentStartDate'>
    ) => {
        const now = moment();
        const selectedPaymentDate = newValue == null ? '' : newValue.format(DATE_SERVER_FORMAT);
        const maxFirstPaymentDate = getMaxDate([term.latestFirstPaymentDate, now.format(DATE_SERVER_FORMAT)]);
        const firstPaymentDate = moment(selectedPaymentDate);
        const isValid =
            now.isSameOrBefore(firstPaymentDate, 'days') && maxFirstPaymentDate.isSameOrAfter(firstPaymentDate, 'days');
        if (isValid) {
            setFirstPaymentDateError(null);
            debounceFn(selectedPaymentDate, field);
        } else {
            setFirstPaymentDateError(
                `Must be between ${moment().format(DATE_ERROR_FORMAT)} and ${maxFirstPaymentDate.format(DATE_ERROR_FORMAT)}`
            );
        }
    };

    const debounceFn = useMemo(
        () =>
            debounce(
                (firstPaymentDate: string, field: ControllerRenderProps<PaymentFormFields, 'paymentStartDate'>) => {
                    queryPaymentConfig(firstPaymentDate).then(() => field.onChange(firstPaymentDate));
                },
                400
            ),
        []
    );

    return (
        <Box sx={{ mt: 4 }}>
            {term.paymentFrequency !== PaymentFrequency.IN_FULL && (
                <Typography variant='h5' component='h2' sx={{ mb: 2 }}>
                    First payment
                </Typography>
            )}

            {term && (
                <FormControl fullWidth required sx={{ mb: 2 }}>
                    <FormLabel htmlFor='startDate' data-testid='paymentStartDate'>
                        {label}
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                            name='paymentStartDate'
                            control={control}
                            defaultValue={undefined}
                            render={({ field }) => (
                                <>
                                    <DatePicker
                                        onChange={(newValue: Moment | null) => {
                                            handleFieldChange(newValue, field);
                                        }}
                                        onAccept={(newValue: Moment | null) => {
                                            handleFieldChange(newValue, field);
                                        }}
                                        value={moment(field.value, DATE_SERVER_FORMAT)}
                                        inputRef={field.ref}
                                        slotProps={{
                                            textField: {
                                                id: 'startDate',
                                                fullWidth: true,
                                                helperText: firstPaymentDateError,
                                                size: 'small',
                                                error: !!firstPaymentDateError,
                                            },
                                        }}
                                        format={DATE_ERROR_FORMAT}
                                        minDate={moment(startDate, DATE_SERVER_FORMAT)}
                                        maxDate={moment(maxDate, DATE_SERVER_FORMAT)}
                                        disabled={!editable}
                                    />
                                    {!editable && (
                                        <Typography sx={{ fontSize: '12px' }}>
                                            Payment for this cover can not be scheduled
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>
            )}
        </Box>
    );
};

export default PaymentStartDateControl;
