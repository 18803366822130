import { CheckoutStatus } from '../../apis/checkoutFlowStatus';
import LayoutWrapper from '../../components/LayoutWrapper';
import StatusGuard from '../../components/StatusGuard';
import Payment from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper>
            <StatusGuard status={CheckoutStatus.PAYMENT} route={'/payment'}>
                <Payment />
            </StatusGuard>
        </LayoutWrapper>
    );
}
