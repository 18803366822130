import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { fetchInvoiceSummaryRecoveredUsingCorrectionToken } from '../../apis/checkout';
import { FetchWrapper } from '../../components/FetchWrapper';
import { useAppDispatch } from '../../store/reducer/Hooks';
import { setAppInvoice } from '../../store/reducer/InvoiceReducer';
import { setSelectedTerm } from '../../store/reducer/SelectedTermReducer';
import { isNil } from 'lodash';

const BankAccountCorrection = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { uuid, token } = useParams<{ uuid: string; token: string }>();

    const state = useFetch(
        async () => {
            return fetchInvoiceSummaryRecoveredUsingCorrectionToken(uuid as string, token as string).then(
                (invoiceSummary) => {
                    dispatch(setAppInvoice(invoiceSummary));

                    if (!isNil(invoiceSummary?.term)) {
                        dispatch(setSelectedTerm(invoiceSummary.term));
                    }

                    navigate('/payment-correction', { state: token });
                    return invoiceSummary;
                }
            );
        },
        [uuid, token],
        { canFetch: () => !isNil(token) }
    );

    return <FetchWrapper state={state} SuccessComponent={Empty} />;
};

const Empty = () => <div data-testid='bankAccountCorrection' />;

export default BankAccountCorrection;
