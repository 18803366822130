import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { fetchInvoiceSummary } from '../../apis/checkout';
import { FetchWrapper } from '../../components/FetchWrapper';
import { useAppDispatch } from '../../store/reducer/Hooks';
import { setAppInvoice, setAppInvoiceUuid } from '../../store/reducer/InvoiceReducer';
import { CheckoutProviderType } from '../../apis/invoice';

const uuidRegex: RegExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

const checkoutPlanRoutes = {
    [CheckoutProviderType.DEFAULT_PF]: '/invoice',
    [CheckoutProviderType.SIMPLE]: '/payment',
    [CheckoutProviderType.CLIENT_INITIATED]: '/payment',
};

const GetStarted = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { uuid } = useParams<{ uuid?: string }>();

    const state = useFetch(
        async () => {
            if (!uuid?.match(uuidRegex)) {
                navigate('/not-found');
                return;
            }

            dispatch(setAppInvoiceUuid(uuid));

            return fetchInvoiceSummary(uuid).then((state) => {
                dispatch(setAppInvoice(state));
                navigate(checkoutPlanRoutes[state.checkoutProviderType], { state });
                return state;
            });
        },
        [uuid],
        { canFetch: () => !!uuid }
    );

    return <FetchWrapper state={state} SuccessComponent={Empty} />;
};

const Empty = () => <div data-testid='getStarted' />;

export default GetStarted;
