import { Add } from '@mui/icons-material';
import {
    Avatar,
    Divider,
    FormControl,
    FormLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    TextField,
    useTheme,
} from '@mui/material';
import { chain, debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Control, UseFormClearErrors, UseFormSetValue } from 'react-hook-form/dist/types/form';
import {
    MatchResponse,
    MatchedAddresses,
    fetchAddressRequestPromise,
    fetchAddressesRequestPromise,
} from '../../apis/address';
import { Address, InsuredType } from '../../apis/checkout';
import { ClientLead } from '../../apis/clientLead';

type AddressFieldsProps = {
    address?: Address;
    control: Control<ClientLead>;
    errors: FieldErrors;
    setValue: UseFormSetValue<ClientLead>;
    clearErrors: UseFormClearErrors<ClientLead>;
    insuredType?: InsuredType;
    required?: boolean;
};

export default function AddressFields({
    address,
    control,
    errors,
    setValue,
    clearErrors,
    insuredType,
    required,
}: Readonly<AddressFieldsProps>) {
    const theme = useTheme();
    const query = useRef<string>();
    const [matchedAddresses, setMatchedAddresses] = useState({} as MatchedAddresses);

    const searchAddresses = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // clear current value when starting a new search
        setAddresses();
        query.current = e.currentTarget.value;
        fetchAddresses();
    };

    const setAddresses = (address?: MatchResponse) => {
        setValue('address.addressLine1', address?.address1 ?? '');
        setValue('address.addressLine2', address?.address2 ?? '');
        setValue('address.suburb', address?.suburb ?? '');
        setValue('address.city', address?.city ?? '');
        setValue('address.postCode', address?.postcode ?? '');
    };

    const fetchSelectedAddress = async (id: number) => {
        fetchAddressRequestPromise(id)
            .then((response) => {
                setAddresses(response);
                setValue('fullAddress' as keyof ClientLead, response.full);
                clearErrors('address.addressLine1');
            })
            .catch(console.log);
    };

    const fetchAddresses = useMemo(
        () =>
            debounce(async () => {
                if (query.current && query.current.length > 3) {
                    fetchAddressesRequestPromise(query.current)
                        .then((response) => {
                            setMatchedAddresses(response);
                        })
                        .catch(console.log);
                }
            }, 200),
        []
    );

    const renderMatchedAddresses = () => {
        return matchedAddresses?.addresses?.map((address) => {
            return (
                <ListItem disableGutters sx={{ padding: 0 }} key={address.id}>
                    <ListItemButton onClick={(_e) => selectAddress(address.id)}>
                        <ListItemText primary={address.a} />
                    </ListItemButton>
                </ListItem>
            );
        });
    };

    const [enterManually, setEnterManually] = useState(false);

    const enterAddressManaully = () => {
        setEnterManually(true);
    };

    const selectAddress = (id: number) => {
        fetchSelectedAddress(id);
        setMatchedAddresses({} as MatchedAddresses);
    };

    return (
        <>
            <FormControl fullWidth style={{ display: enterManually ? 'none' : 'inherit' }} required={required}>
                <FormLabel sx={{ mb: 1 }} htmlFor='fullAddress' error={!!errors.addressLine1}>
                    {getAddressLabel(insuredType)}
                </FormLabel>
                <div style={{ position: 'relative', width: '100%' }}>
                    <Controller
                        name={'fullAddress' as keyof ClientLead}
                        control={control}
                        defaultValue={buildFullAddress(address) ?? ''}
                        render={({ field: { onChange, ...rest } }) => (
                            <TextField
                                {...rest}
                                id='fullAddress'
                                placeholder='Start typing...'
                                autoComplete='no'
                                fullWidth
                                onChange={(e) => {
                                    onChange(e);
                                    searchAddresses(e);
                                }}
                                error={!!errors.addressLine1}
                                helperText={errors.addressLine1?.message as string}
                            />
                        )}
                    />
                    {matchedAddresses?.addresses && (
                        <Paper sx={{ position: 'absolute', zIndex: 9, width: '100%' }}>
                            <List sx={{ padding: 0, maxHeight: '400px', overflowY: 'auto' }}>
                                {renderMatchedAddresses()}
                                <Divider />
                                <ListItem disableGutters sx={{ padding: 0, lineHeight: '24px' }} key='new-client'>
                                    <ListItemButton onClick={enterAddressManaully}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                                                <Add />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Address not found, enter manually' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Paper>
                    )}
                </div>
            </FormControl>

            <FormControl fullWidth style={{ display: enterManually ? '' : 'none' }} required={required}>
                <FormLabel sx={{ mb: 1 }} error={!!errors.addressLine1} htmlFor='addressLine1'>
                    <span style={{ marginBottom: theme.spacing(1), display: 'block' }}>
                        {getAddressLabel(insuredType)}
                    </span>
                    <span>Line 1</span>
                </FormLabel>
                <Controller
                    name='address.addressLine1'
                    control={control}
                    defaultValue={address?.addressLine1 ?? ''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='addressLine1'
                            error={!!errors.addressLine1}
                            helperText={errors.addressLine1?.message as string}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth style={{ display: enterManually ? '' : 'none' }}>
                <FormLabel sx={{ mb: 1 }} error={!!errors.addressLine2} htmlFor='addressLine2'>
                    Line 2
                </FormLabel>
                <Controller
                    name='address.addressLine2'
                    control={control}
                    defaultValue={address?.addressLine2 ?? ''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='addressLine2'
                            autoComplete='no'
                            error={!!errors.addressLine2}
                            helperText={errors.addressLine2?.message as string}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth style={{ display: enterManually ? '' : 'none' }}>
                <FormLabel sx={{ mb: 1 }} error={!!errors.suburb} htmlFor='suburb'>
                    Suburb
                </FormLabel>
                <Controller
                    name='address.suburb'
                    control={control}
                    defaultValue={address?.suburb ?? ''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='suburb'
                            error={!!errors.suburb}
                            helperText={errors.suburb?.message as string}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth style={{ display: enterManually ? '' : 'none' }}>
                <FormLabel sx={{ mb: 1 }} error={!!errors.cityTown} htmlFor='cityTown'>
                    City / Town{' '}
                </FormLabel>
                <Controller
                    name='address.city'
                    control={control}
                    defaultValue={address?.city ?? ''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='cityTown'
                            error={!!errors.cityTown}
                            helperText={errors.cityTown?.message as string}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth style={{ display: enterManually ? '' : 'none' }}>
                <FormLabel sx={{ mb: 1 }} error={!!errors.postcode} htmlFor='postcode'>
                    Postcode
                </FormLabel>
                <Controller
                    name='address.postCode'
                    control={control}
                    defaultValue={address?.postCode ?? ''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='postcode'
                            error={!!errors.postcode}
                            helperText={errors.postcode?.message as string}
                        />
                    )}
                />
            </FormControl>
        </>
    );
}

const getAddressLabel = (insuredType?: InsuredType): string => {
    switch (insuredType) {
        case InsuredType.BUSINESS:
            return 'Registered business address';
        case InsuredType.TRUST:
            return 'Registered trust address';
        default:
            return 'Home address';
    }
};

const buildFullAddress = (address?: Address): string | undefined => {
    if (address == null) {
        return undefined;
    }

    const { addressLine1, addressLine2, suburb, city, postCode } = address;

    const addressWithoutPostCode = chain([addressLine1, addressLine2, suburb, city])
        .filter((part) => part != null && part !== '')
        .join(', ')
        .value();

    return `${addressWithoutPostCode}${postCode ? ' ' + postCode : ''}`;
};
