import { Authenticator } from '@aws-amplify/ui-react';
import { LoginHeader } from '../../components/AuthHeader';
import LayoutWrapper from '../../components/LayoutWrapper';
import LoanContract from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper wider>
            <Authenticator hideSignUp={true} components={{ Header: LoginHeader }}>
                <LoanContract />
            </Authenticator>
        </LayoutWrapper>
    );
}
