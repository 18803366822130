import { Divider, Grid, Typography } from '@mui/material';
import styles from '../../style/app.module.css';

interface BannerProps {
    sellerLogoUrl?: string;
}

const Banner = (props: BannerProps) => {
    const { sellerLogoUrl } = props;

    return (
        <>
            <Grid container>
                <Grid item xs={6} paddingTop={4} sx={{ fontWeight: 600, fontSize: '18px' }}>
                    <Typography variant='h2'>Payment Summary</Typography>
                </Grid>
                <Grid item xs={6} className={styles.sim_text_right}>
                    {sellerLogoUrl && (
                        <img src={sellerLogoUrl} style={{ maxWidth: '200px', width: '100%' }} alt='Insurance Logo' />
                    )}
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
        </>
    );
};

export default Banner;
