import { createSlice } from '@reduxjs/toolkit';
import { PaymentDetail } from '../../apis/payment';

export interface PaymentPlanAction {
    type: string;
    payload: PaymentDetail;
}

export const paymentPlanSlice = createSlice({
    name: 'paymentPlan',
    initialState: {
        value: {} as PaymentDetail,
    },
    reducers: {
        setPaymentPlan: (state, action: PaymentPlanAction) => {
            state.value = action.payload;
        },
    },
});

export const { setPaymentPlan } = paymentPlanSlice.actions;

export default paymentPlanSlice.reducer;
