import { Client } from '../../apis/checkout';
import { FetchClientAction } from '../../type/Types';
import { createSlice } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
    name: 'client',
    initialState: {
        value: {} as Client,
    },
    reducers: {
        setInvoiceClient: (state, action: FetchClientAction) => {
            state.value = action.payload;
        },
    },
});

export const { setInvoiceClient } = clientSlice.actions;

export default clientSlice.reducer;
