import SimplifiedPaymentComplete from './SimplifiedPaymentComplete';

const PaymentComplete = () => {
    return (
        <div data-testid='simplifiedPaymentComplete'>
            <SimplifiedPaymentComplete />
        </div>
    );
};

export default PaymentComplete;
