import axios, { AxiosResponse } from 'axios';
import ClientDetails from './ClientDetails';
import InvoiceTotals from './InvoiceTotals';
import { ArrowForward } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setInvoiceClient } from '../../store/reducer/ClientReducer';
import { Alert, Typography } from '@mui/material';
import { fetchLoanSummary, LoanSummary } from '../../apis/checkout';
import { useFetch } from '../../hooks/useFetch';
import { FetchWrapper } from '../../components/FetchWrapper';
import { LoadingButton } from '../../components/LoadingButton';
import PaymentSummary from '../../components/PaymentSummary';
import { TermPaymentConfig } from '../../apis/term';
import { find } from 'lodash';

const RepaymentSummary = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const invoice = useAppSelector((root) => root.persistedInvoiceReducer).value;
    const persistedTerm = useAppSelector((root) => root.persistedTermReducer).value;
    const clientLeadRequest = useAppSelector((root) => root.persistedUnsolicitedPaymentReducer).clientLeadRequest;

    const [loadingSubmitRes, setLoadingSubmitRes] = useState(false);
    const [term, setTerm] = useState<TermPaymentConfig | undefined>(undefined);

    const fetchClientState = useFetch(
        () =>
            fetchLoanSummary(invoice).then((res) => {
                dispatch(setInvoiceClient(res.insured));
                setTerm(
                    find(
                        res.paymentConfig.termPaymentConfig,
                        (termPaymentConfig) => termPaymentConfig.paymentFrequency === persistedTerm.paymentFrequency
                    )
                );
                return res;
            }),
        [invoice]
    );

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingSubmitRes(true);
        const confirmLoanDetailsRequest = async (): Promise<AxiosResponse> => {
            return await axios
                .put(process.env.REACT_APP_CHECKOUT_HOST + '/checkout/' + invoice.uuid + '/confirm-loan-details')
                .then((response) => {
                    return response;
                });
        };

        confirmLoanDetailsRequest()
            .then(() => {
                navigate('/payment');
            })
            .finally(() => setLoadingSubmitRes(false));
    };

    const Summary = useCallback(
        ({ data }: { data: LoanSummary }) => {
            return (
                <>
                    <ClientDetails client={data.insured} />
                    <InvoiceTotals invoice={invoice} />
                    <PaymentSummary term={term} transactionFees={[]} />
                </>
            );
        },
        [term]
    );

    return (
        <>
            <Typography variant='h2'>Repayment details</Typography>

            <Typography variant='body1'>
                Premium funding allows you to pay your insurance premium upfront, which you can repay over time.
            </Typography>

            <Typography variant='body1' sx={{ fontWeight: 600, mt: 2 }}>
                This is a short-term loan featuring a fixed interest rate and no ongoing fees.
            </Typography>
            <Typography variant='body1' sx={{ mt: 2 }}>
                The instalment details are outlined below.
            </Typography>

            {clientLeadRequest != null && (
                <Alert severity='info' sx={{ mt: 3 }}>
                    Loan is subject to funder approval. If declined, the full remaining balance will be due. Approval
                    may take a few days.
                </Alert>
            )}

            <FetchWrapper state={fetchClientState} SuccessComponent={Summary} />

            <form onSubmit={handleSubmit}>
                <LoadingButton
                    loading={loadingSubmitRes}
                    type='submit'
                    fullWidth
                    sx={{ mt: '20px' }}
                    endIcon={<ArrowForward />}
                    variant='contained'
                >
                    Next
                </LoadingButton>
            </form>
        </>
    );
};

export default RepaymentSummary;
