import { Auth } from 'aws-amplify';
import { AxiosRequestConfig } from 'axios';

export const axiosAuthRequestInterceptor = async (config: AxiosRequestConfig) => {
    try {
        const session = await Auth.currentSession();

        const token = session.getIdToken().getJwtToken();
        if (token) {
            config.headers!.Authorization = 'Bearer ' + token;
        }
    } catch {
        // sometimes this will happen if the route is genuinely unprotected
    }

    config.headers!.Accept = 'application/vnd.simfuni-client_payment.v1.0+json';
    config.headers!['Content-type'] = 'application/vnd.simfuni-client_payment.v1.0+json';

    return config;
};
