import Header from './Header';
import Banner from './Banner';
import InvoiceDetails from './InvoiceDetails';
import { useEffect, useState } from 'react';
import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import { InvoiceSummary } from '../../type/Types';
import { useAppDispatch } from '../../store/reducer/Hooks';
import { setAppInvoice } from '../../store/reducer/InvoiceReducer';

const Invoice = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const invoice = location.state as InvoiceSummary;

    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    const acceptInvoice = () => {
        dispatch(setAppInvoice(invoice));
        navigate('/payment-options');
    };

    useEffect(() => {
        if (invoice == null) {
            navigate('/');
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <>
            {loading ? (
                <PageLoading />
            ) : (
                <>
                    {invoice.status === 'PAID' ? (
                        <Alert color='success'>Invoice {invoice.number} has been paid</Alert>
                    ) : (
                        <>
                            <Typography variant='h2' component='h1'>
                                Let's get you covered
                            </Typography>

                            <Typography variant={'body1'}>
                                Review your insurance quote below and continue when ready.
                            </Typography>

                            <Paper sx={{ p: 3, mt: 3 }}>
                                <Banner sellerLogoUrl={invoice.sellerLogoUrl} />

                                <Header invoice={invoice} />

                                <InvoiceDetails invoice={invoice} />
                            </Paper>

                            <Box marginTop={'15px'}>
                                <Button onClick={acceptInvoice} fullWidth variant='contained'>
                                    Pay
                                </Button>
                            </Box>

                            <Box marginTop={'15px'} sx={{ textAlign: 'center' }}>
                                <Button component={Link} to={'/'} fullWidth>
                                    Doesn't look right?
                                </Button>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Invoice;
