import { useEffect } from 'react';
import { useHeightPush } from '../hooks/useHeightPush';
import { useLocation } from 'react-router-dom';

export default function ManageIntegratedView() {
    const location = useLocation();
    const { reset } = useHeightPush();

    useEffect(() => {
        reset();
    }, [location]);

    return null;
}
