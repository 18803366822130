import { createSlice } from '@reduxjs/toolkit';

export type PageWidthAction = {
    type: string;
    payload: boolean;
};

export const pageWidthSlice = createSlice({
    name: 'breakpoint',
    initialState: {
        value: false,
    },
    reducers: {
        setPageWider: (state, action: PageWidthAction) => {
            state.value = action.payload;
        },
    },
});

export const { setPageWider } = pageWidthSlice.actions;

export default pageWidthSlice.reducer;
