import { Typography } from '@mui/material';
import { FetchState, FetchStateType } from '../hooks/useFetch';
import PageLoading from './PageLoading';
import ErrorMessage from './ErrorMessage';

export type FetchStateProps<T> = {
    state: FetchState<T>;
    SuccessComponent: React.FC<{ data: T }>;
};

type ExtendableFetchStateProps<T, REST> = REST &
    Omit<FetchStateProps<T>, 'SuccessComponent'> & {
        SuccessComponent: React.FC<{ data: T } & REST>;
    };

/**
 * See {@link https://simfuni.atlassian.net/wiki/spaces/ENGINEERIN/pages/240517167/99.0.13+useFetch+and+FetchWrapper#ExtendableFetchWrapper Confluence}
 * for more details, examples, and use cases.
 *
 * Takes a @see FetchState, a @see React.FC, and any other props to add to the SuccessComponent.
 *
 * When state is:
 * @see FetchStateType.PENDING @see FetchStateType.LOADING → show a loading spinner
 * @see FetchStateType.UNAVAILABLE → show a not found message
 * @see FetchStateType.ERROR → show an error message
 * @see FetchStateType.SUCCESS → show the SuccessComponent @param {React.FC}
 *
 * @returns JSX Element
 */
export const ExtendableFetchWrapper = <T, REST>({
    state,
    SuccessComponent,
    ...rest
}: ExtendableFetchStateProps<T, REST>) => {
    if ([FetchStateType.LOADING, FetchStateType.PENDING].includes(state.type)) {
        return <PageLoading />;
    }

    if (state.type === FetchStateType.ERROR) {
        return <ErrorMessage />;
    }

    if (state.type === FetchStateType.SUCCESS) {
        return <SuccessComponent data={state.value} {...(rest as unknown as REST)} />;
    }

    if (state.type === FetchStateType.UNAVAILABLE) {
        return (
            <Typography sx={{ textAlign: 'center', paddingTop: 5, minHeight: '230px' }}>
                We could not find what you are looking for. Please refresh your page or contact us for support.
            </Typography>
        );
    }

    return null;
};
