import { SelectTermAction } from '../../type/Types';
import { createSlice } from '@reduxjs/toolkit';
import { Term } from '../../apis/term';

export const selectedTermSlice = createSlice({
    name: 'selectedTerm',
    initialState: {
        value: {} as Term,
    },
    reducers: {
        setSelectedTerm: (state, action: SelectTermAction) => {
            state.value = action.payload;
        },
        resetTerm: (state) => {
            state.value = {} as Term;
        },
    },
});

export const { setSelectedTerm, resetTerm } = selectedTermSlice.actions;

export default selectedTermSlice.reducer;
