import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { ChargeType, PaymentFrequency } from '../apis/term';
import { FeeConfig } from '../functions/feeUtils';
import { DATE_DISPLAY_FORMAT, getInstalmentDescription } from '../functions/instalmentUtils';
import moment from 'moment/moment';
import { PaymentSummary } from '../apis/payment';
import _ from 'lodash';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

interface PortfolioSummaryProps {
    feeConfig?: FeeConfig;
    paymentSummary: PaymentSummary;
}

const PaymentPlanDetail = (props: PortfolioSummaryProps) => {
    const { paymentSummary, feeConfig } = props;
    const [setupFeeTotal, setSetupFeeTotal] = useState<number>(0);
    const [finalPaymentDate, setFinalPaymentDate] = useState<string | null>(null);

    useEffect(() => {
        if (paymentSummary?.finalPaymentDate) {
            setFinalPaymentDate(moment(paymentSummary.finalPaymentDate).format(DATE_DISPLAY_FORMAT));
        }
    }, [paymentSummary?.finalPaymentDate]);

    useEffect(() => {
        if (paymentSummary?.fees?.length) {
            const setupFeeTotal = paymentSummary.fees
                .filter((charge) => charge.type === ChargeType.SETUP_FEE)
                .reduce((accumulator, charge) => {
                    return accumulator + charge.initialAmount;
                }, 0);
            setSetupFeeTotal(setupFeeTotal);
        }
    }, [paymentSummary?.fees]);

    const getFirstPaymentDesc = (firstPaymentDate: string) => {
        const today = moment();
        if (today.isSame(firstPaymentDate, 'days')) {
            return 'Today';
        }
        return `on ${moment(firstPaymentDate).format(DATE_DISPLAY_FORMAT)}`;
    };

    const getFirstPaymentAmount = (feeConfig: FeeConfig) => {
        return paymentSummary.firstPaymentAmount + feeConfig.upfrontPaymentFee;
    };

    if (!feeConfig) {
        return null;
    }

    return (
        <Grid container rowSpacing='12'>
            <Grid item xs={12}></Grid>
            <Grid item xs={5}>
                <Typography variant='body1'>
                    {paymentSummary.paymentFrequency === PaymentFrequency.IN_FULL ? 'Payment' : 'First instalment'}
                </Typography>
            </Grid>
            <Grid item xs={7} sx={{ textAlign: 'right' }}>
                <Typography variant='body1' data-testid='firstPaymentAmount'>
                    {currencyFormatter.format(getFirstPaymentAmount(feeConfig))}{' '}
                    {paymentSummary.firstPaymentDate && getFirstPaymentDesc(paymentSummary.firstPaymentDate)}
                </Typography>
                {setupFeeTotal ? (
                    <Typography variant='subtitle1'>
                        {`includes ${currencyFormatter.format(setupFeeTotal)} setup fee`}
                    </Typography>
                ) : (
                    <></>
                )}
            </Grid>
            {paymentSummary.paymentFrequency !== PaymentFrequency.IN_FULL && (
                <>
                    <Grid item xs={5}>
                        <Typography variant='body1'>Ongoing instalments</Typography>
                    </Grid>
                    <Grid item xs={7} sx={{ textAlign: 'right' }}>
                        <Typography variant='body1' data-testid='instalmentPaymentAmount'>
                            {`${currencyFormatter.format(paymentSummary.instalmentPaymentAmount + feeConfig.instalmentPaymentFee)}`}
                        </Typography>
                        {paymentSummary.firstPaymentDate != null && (
                            <Typography variant='subtitle1' data-testid='instalmentDescription'>
                                {getInstalmentDescription(
                                    paymentSummary.paymentFrequency,
                                    paymentSummary.firstPaymentDate
                                )}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1'>Term</Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ textAlign: 'right' }}>
                        <Typography variant='body1' data-testid='termInfo'>
                            {`${paymentSummary.numberOfPayments} ${_.lowerCase(paymentSummary.paymentFrequency)} instalments`}
                        </Typography>
                        {finalPaymentDate != null && (
                            <Typography variant='subtitle1'>{`ending ${finalPaymentDate}`}</Typography>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default PaymentPlanDetail;
