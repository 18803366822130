import { createSlice } from '@reduxjs/toolkit';
import { OriginationConfig, UIConfig } from '../../apis/seller';
import { Action } from './Hooks';

const initialState: { uiConfig?: UIConfig; originationConfig?: OriginationConfig; integratedView: boolean } = {
    uiConfig: undefined,
    originationConfig: undefined,
    integratedView: false,
};

export const sellerSlice = createSlice({
    name: 'seller',
    initialState,
    reducers: {
        setSellerUIConfig: (state, action: Action<UIConfig>) => {
            state.uiConfig = action.payload;
        },
        setIntegratedView: (state, _action: Action<void>) => {
            state.integratedView = true;
        },
        setSellerOriginationConfig: (state, action: Action<OriginationConfig | undefined>) => {
            state.originationConfig = action.payload;
        },
    },
});

export const { setSellerUIConfig, setIntegratedView, setSellerOriginationConfig } = sellerSlice.actions;

export default sellerSlice.reducer;
