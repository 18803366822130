export function internationalise(dialingCode: string, number: string) {
    if (number.startsWith(dialingCode)) {
        return number;
    }

    if (number.startsWith('0')) {
        return dialingCode + number;
    }

    return dialingCode + number.slice(1);
}
