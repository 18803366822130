import { Circle, Done } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

type Props = { password: string; message: string; regex: RegExp };
export default ({ password, message, regex }: Props) => {
    const [passes, setPasses] = useState(false);
    useEffect(() => {
        setPasses(regex.test(password));
    }, [password, regex]);

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{ width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {passes ? (
                    <Done fontSize='small' color='success' data-testid='valid' />
                ) : (
                    <Circle sx={{ fontSize: '8px' }} data-testid='invalid' />
                )}
            </Box>
            <span className='validation-message'>{message}</span>
        </Box>
    );
};
