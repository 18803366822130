import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { InsuredType } from '../../apis/checkout';
import { ClientLead, createClientLead } from '../../apis/clientLead';
import { OriginationConfig } from '../../apis/seller';
import { LoadingButton } from '../../components/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { resetInvoice } from '../../store/reducer/InvoiceReducer';
import { resetTerm } from '../../store/reducer/SelectedTermReducer';
import { setClientLead, setClientLeadRequest } from '../../store/reducer/UnsolicitedPaymentReducer';
import AddressFields from './AddressFields';

export default function PersonalDetails() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const invoice = useAppSelector((state) => state.persistedInvoiceReducer).value;
    const { clientLead, reference, paymentAmount, clientLeadRequest } = useAppSelector(
        (root) => root.persistedUnsolicitedPaymentReducer
    );
    const { originationConfig } = useAppSelector((root) => root.persistedSellerReducer);
    const [schema, setSchema] = useState<yup.SchemaOf<ClientLead>>();
    const [createError, setCreateError] = useState<string>();
    const [loading, setLoading] = useState(false);

    const {
        control,
        watch,
        setValue,
        clearErrors,
        formState: { errors },
        handleSubmit,
    } = useForm<ClientLead>({
        resolver: yupResolver(schema!),
    });

    const insuredType = watch('insuredType');

    useEffect(() => {
        setSchema(getPersonalDetailsSchema(originationConfig!));
    }, [originationConfig]);

    const submitHandler = ({ fullAddress: _fullAddress, ...data }: ClientLead & { fullAddress: string }) => {
        setLoading(true);
        setCreateError(undefined);
        const clientLead = {
            ...data,
            reference: reference!,
        };
        const newClientLeadRequest = { clientLead, invoiceAmount: paymentAmount! };
        dispatch(setClientLead(clientLead));

        if (isEqual(newClientLeadRequest, clientLeadRequest) && invoice != null) {
            // this would happen if they already created a client lead and have then gone back
            // rather if the details in the lead are exactly the same, we don't make a new one
            // we send them to the already created one
            navigate(`/${invoice.uuid}`);
            return;
        }

        dispatch(resetInvoice());
        dispatch(resetTerm());
        createClientLead(newClientLeadRequest)
            .then((createdInvoice) => {
                dispatch(setClientLeadRequest(newClientLeadRequest));
                navigate(`/${createdInvoice.uuid}`);
            })
            .catch(() => {
                setCreateError('Something went wrong, please try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(submitHandler as SubmitHandler<ClientLead>)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Box>
                    <Typography variant='h5' component='h1' mb={1}>
                        Personal details
                    </Typography>
                    <Typography variant='caption'>
                        Enter your personal details below. This will help match your payment to your policy, and we'll
                        send through a payment receipt.
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {originationConfig?.insuredTypes && originationConfig.insuredTypes.length > 0 && (
                        <FormControl fullWidth required>
                            <FormLabel sx={{ mb: 1 }} htmlFor='insuredType' error={!!errors.insuredType}>
                                Who is the insurance for?
                            </FormLabel>
                            <Controller
                                name='insuredType'
                                control={control}
                                defaultValue={clientLead?.insuredType ?? ('' as InsuredType)}
                                render={({ field }) => {
                                    return (
                                        <ToggleButtonGroup
                                            {...field}
                                            exclusive
                                            onChange={(_event: React.MouseEvent<HTMLElement>, value: string) => {
                                                setValue(field.name, value as InsuredType);
                                            }}
                                            id='insuredType'
                                            color={errors.insuredType ? 'error' : 'primary'}
                                        >
                                            {originationConfig.insuredTypes!.includes(InsuredType.INDIVIDUAL) && (
                                                <ToggleButton
                                                    value={InsuredType.INDIVIDUAL}
                                                    aria-label={InsuredType.INDIVIDUAL}
                                                >
                                                    Individual
                                                </ToggleButton>
                                            )}
                                            {originationConfig.insuredTypes!.includes(InsuredType.BUSINESS) && (
                                                <ToggleButton
                                                    value={InsuredType.BUSINESS}
                                                    aria-label={InsuredType.BUSINESS}
                                                >
                                                    Business
                                                </ToggleButton>
                                            )}
                                            {originationConfig.insuredTypes!.includes(InsuredType.TRUST) && (
                                                <ToggleButton value={InsuredType.TRUST} aria-label={InsuredType.TRUST}>
                                                    Trust
                                                </ToggleButton>
                                            )}
                                        </ToggleButtonGroup>
                                    );
                                }}
                            />
                            {errors.insuredType && <FormHelperText error>{errors.insuredType.message}</FormHelperText>}
                        </FormControl>
                    )}

                    {originationConfig?.name !== 'disabled' && (
                        <FormControl fullWidth required={originationConfig?.name === 'required'}>
                            <FormLabel sx={{ mb: 1 }} htmlFor='name' error={!!errors.name}>
                                {getNameLabel(insuredType)}
                            </FormLabel>
                            <Controller
                                name='name'
                                control={control}
                                defaultValue={clientLead?.name ?? ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='name'
                                        error={errors.name !== undefined}
                                        helperText={errors.name?.message}
                                        inputProps={{ maxLength: 70 }}
                                    />
                                )}
                            />
                        </FormControl>
                    )}

                    <FormControl fullWidth required>
                        <FormLabel sx={{ mb: 1 }} htmlFor='emailAddress' error={!!errors.emailAddress}>
                            {getEmailLabel(insuredType)}
                        </FormLabel>
                        <Controller
                            name='emailAddress'
                            control={control}
                            defaultValue={clientLead?.emailAddress ?? ''}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id='emailAddress'
                                    error={errors.emailAddress !== undefined}
                                    helperText={
                                        errors.emailAddress?.message ?? "We'll email you a receipt for the payment."
                                    }
                                    inputProps={{ maxLength: 50 }}
                                />
                            )}
                        />
                    </FormControl>

                    {originationConfig?.number !== 'disabled' && (
                        <FormControl fullWidth required={originationConfig?.number === 'required'}>
                            <FormLabel sx={{ mb: 1 }} htmlFor='mobileNumber' error={!!errors.mobileNumber}>
                                {getMobileLabel(insuredType)}
                            </FormLabel>
                            <Controller
                                name='mobileNumber'
                                control={control}
                                defaultValue={clientLead?.mobileNumber ?? ''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='mobileNumber'
                                        error={errors.mobileNumber !== undefined}
                                        helperText={errors.mobileNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    )}

                    {originationConfig?.address !== 'disabled' && (
                        <AddressFields
                            address={clientLead?.address}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            insuredType={insuredType}
                            required={originationConfig?.address === 'required'}
                        />
                    )}
                    {createError && <Alert severity='error'>{createError}</Alert>}
                </Box>

                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button sx={{ width: '100%' }} variant='text' onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    <LoadingButton loading={loading} sx={{ width: '100%' }} type='submit' variant='contained'>
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}

const getEmailLabel = (insuredType?: InsuredType): string => {
    switch (insuredType) {
        case InsuredType.BUSINESS:
        case InsuredType.TRUST:
            return 'Contact email address';
        default:
            return 'Email address';
    }
};

const getMobileLabel = (insuredType?: InsuredType): string => {
    switch (insuredType) {
        case InsuredType.BUSINESS:
        case InsuredType.TRUST:
            return 'Contact mobile number';
        default:
            return 'Mobile number';
    }
};

const getNameLabel = (insuredType?: InsuredType): string => {
    switch (insuredType) {
        case InsuredType.BUSINESS:
            return 'Business name';
        case InsuredType.TRUST:
            return 'Trust name';
        default:
            return 'Name';
    }
};

const getPersonalDetailsSchema = (originationConfig: OriginationConfig): yup.SchemaOf<ClientLead> => {
    const schema: Partial<Record<keyof ClientLead, yup.AnySchema>> = {
        emailAddress: yup.string().email('A valid email is required').required('A valid email is required'),
    };

    if ((originationConfig?.insuredTypes?.length ?? 0) > 0) {
        schema.insuredType = yup
            .mixed<InsuredType>()
            .required('An insured type is required')
            .test('valid-insured-type', 'An insured type is required', (value) => {
                return (Object.values(InsuredType) as string[]).includes(value as string);
            });
    }

    const addressConfig = originationConfig?.address;
    if (addressConfig !== 'disabled') {
        schema.address = yup.object({
            addressLine1: yup.string().test('is-required', 'An address is required', (value) => {
                if (addressConfig !== 'required') return true;
                return !!value && value.length > 0;
            }),
            addressLine2: yup.string().optional(),
            suburb: yup.string().optional(),
            cityTown: yup.string().optional(),
            postcode: yup.string().optional(),
        });
    }

    const nameConfig = originationConfig?.name;
    if (nameConfig !== 'disabled') {
        schema.name = yup.string().test('is-required', `A name is required`, (value) => {
            if (nameConfig !== 'required') return true;
            return !!value && value.length > 0;
        });
    }

    const numberConfig = originationConfig?.number;
    if (numberConfig !== 'disabled') {
        schema.mobileNumber = yup.string().test('is-required', `A valid mobile number is required`, (value) => {
            if (value && !mobileNumberRegex.test(value)) {
                return false;
            }

            if (numberConfig !== 'required') {
                return true;
            }

            return !!value && value.length > 0;
        });
    }

    return yup.object(schema) as yup.SchemaOf<ClientLead>;
};

const mobileNumberRegex = /(^(\+?64|0)2[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$)/;
