import { CheckoutStatus } from '../../apis/checkoutFlowStatus';
import LayoutWrapper from '../../components/LayoutWrapper';
import StatusGuard from '../../components/StatusGuard';
import PaymentProcessing from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper>
            <StatusGuard status={CheckoutStatus.PAYMENT} route={'/payment-processing'}>
                <PaymentProcessing />
            </StatusGuard>
        </LayoutWrapper>
    );
}
