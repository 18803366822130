import { Box, Typography } from '@mui/material';
import { CustomCheckoutType } from '../apis/term';
import iQumulateLogo from '../images/iQumulate.svg';

const PaymentOptionExtraInfo = (props: { customCheckoutType: CustomCheckoutType }) => {
    if (props.customCheckoutType === CustomCheckoutType.IQUMULATE) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
                <img src={iQumulateLogo} style={{ width: '100px' }} alt='IQumulate' data-testid='funderLogo' />
                <Typography variant='subtitle2' component='p' data-testid='funderDescription'>
                    You will be redirected to the premium funding provider IQumulate to complete payment.
                </Typography>
            </Box>
        );
    }

    return null;
};

export default PaymentOptionExtraInfo;
