import { useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PaymentFrequency } from '../apis/term';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { AppPortalType } from '../apis/seller';

interface Props {
    paymentFrequency: PaymentFrequency;
}

const MyAccount = (props: Props) => {
    const { uiConfig } = useSelector((state: RootState) => state.persistedSellerReducer);

    const clientUrl = useMemo(() => {
        return uiConfig?.origins?.[AppPortalType.CLIENT] ?? process.env.REACT_APP_CLIENT_PORTAL;
    }, [uiConfig]);

    const goToMyAccount = () => {
        window.open(clientUrl, '_blank')?.focus();
    };

    if (props.paymentFrequency !== PaymentFrequency.IN_FULL) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                    gap: 3,
                    mt: 4,
                }}
            >
                <Typography sx={{ fontWeight: 600 }}>View policies and manage payments in your account</Typography>
                <Button onClick={goToMyAccount} variant='contained'>
                    My account
                </Button>
            </Box>
        );
    }

    return null;
};

export default MyAccount;
