import { useEffect } from 'react';
import { completeTokenSession } from '../apis/payment';
import { useSearchParams } from 'react-router-dom';

export default function useTokenization() {
    const [searchParams, setSearchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');

    useEffect(() => {
        if (sessionId == null) {
            return;
        }

        completeTokenSession(sessionId)
            .then(removeSessionIdFromUrl)
            .catch(() => {
                removeSessionIdFromUrl();
            });

        return removeSessionIdFromUrl;
    }, [sessionId]);

    const removeSessionIdFromUrl = () => {
        searchParams.delete('sessionId');
        setSearchParams(searchParams);
    };
}
