import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Charge, ChargeType } from '../apis/term';
import { InfoOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { toLower, upperFirst } from 'lodash';

type Props = {
    termCharges?: Charge[];
};

export default function DefaultFeeHint({ termCharges }: Readonly<Props>) {
    const [openDialog, setOpenDialog] = useState(false);

    const defaultFeeCharges = termCharges?.filter((charge) => charge.type === ChargeType.DEFAULT_FEE) ?? [];
    const defaultFee = defaultFeeCharges.reduce((a, charge) => a + charge.chargeValue, 0) ?? 0;

    if (defaultFee <= 0) {
        return null;
    }

    const label = defaultFeeCharges[0].label ?? 'default fee';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant='subtitle1' component='p'>
                {upperFirst(label)}: {currencyFormat.format(defaultFee)}
            </Typography>
            <IconButton color='primary' size='small' onClick={() => setOpenDialog(true)}>
                <InfoOutlined />
            </IconButton>
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog} maxWidth='xs'>
                <DialogTitle>
                    <Typography variant='h4'>{upperFirst(label)} information</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography mb={4}>
                        If a payment fails, a {currencyFormat.format(defaultFee)} {toLower(label)} applies. You'll be
                        notified about failed payments and when the next payment attempt will occur.
                    </Typography>
                    <Typography>Reattempted payments will include any {toLower(label)} incurred.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
