import { Box, Typography } from '@mui/material';

const AuthHeader = ({ message }: { message: string }) => {
    return (
        <Box sx={{ px: { xs: 2, sm: 6 }, pb: 4, textAlign: 'center' }}>
            <Typography variant='h2' component='h2'>
                {message}
            </Typography>
        </Box>
    );
};

export const LoginHeader = () => {
    return <AuthHeader message='Login to your account so you can manage your payments' />;
};

export const SignUpHeader = () => {
    return <AuthHeader message='Create an account so you can manage your payments going forward' />;
};
