import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { AppPortalType, Origins } from '../apis/seller';

const MAX_MONITOR_COUNT = 10;
const RESET_HEIGHT = 100;
const MUTATION_CONFIG = { attributes: true, childList: true, subtree: true, characterData: true };
const DELAY_TIMER = 5;
const RETRY_BODY_TIMER = 200;
const POLL_PARENTS_TIMER = 300;
const MAX_HEIGHT = 5000;

const timeoutIds = new Set<number>();

/**
 * Listens for mutations to the DOM body, if there are any and the height has changed then
 * events are pushed to potential parents
 */
export const useHeightPush = () => {
    const { uiConfig } = useSelector((state: RootState) => state.persistedSellerReducer);

    const monitorCount = useRef(0);
    const height = useRef(RESET_HEIGHT);
    const [delay, setDelay] = useState(false);
    const [bodyPollId, setBodyPollId] = useState(0);

    useEffect(() => {
        return attachListenerToBody();
    }, [uiConfig?.origins, delay]);

    /**
     * Attach a mutation listener to the body.
     *
     * When a change is made to the body, we poll the parents @see MAX_MONITOR_COUNT number of times
     * with the current height. We poll because the actual DOM may not have finished loading even
     * after the last mutation event is triggered.
     */
    const attachListenerToBody = (): undefined | (() => void) => {
        // Only listen for changes if there is a parents website to alert
        if (!uiConfig?.origins) {
            return;
        }

        const body = document.querySelector('body');
        if (body) {
            const observer = new MutationObserver(observeMutation);
            observer.observe(body, MUTATION_CONFIG);

            return () => {
                observer.disconnect();
                timeoutIds.forEach(clearTimeout);
            };
        } else {
            /** If body is not found yet (e.g. virtual DOM is still copying to real DOM) then try again @see RETRY_BODY_TIMER ms */
            setBodyPollId(window.setTimeout(attachListenerToBody, RETRY_BODY_TIMER));
            return () => {
                clearTimeout(bodyPollId);
            };
        }
    };

    /**
     * Start polling the parents with the height changes.
     * If @see delay is set, then we send the first current height message to the parents after @see DELAY_TIMER ms.
     * There is a delay when the page first loads so we can first send (and the parents can register) a reset event.
     * Resetting the height on page change is important to not overinflate the height.
     */
    const observeMutation = () => {
        setTimeout(updateHeight, delay ? DELAY_TIMER : 0);
        setDelay(false);
        monitorCount.current = 0;
        pollParents();
    };

    /**
     * Find the current height of the html and send it to the parents
     */
    const updateHeight = () => {
        const scrollHeight = document.querySelector('html')?.scrollHeight ?? RESET_HEIGHT;
        if (scrollHeight !== height.current) {
            height.current = scrollHeight;
            alertHeightToParents(Math.min(scrollHeight, MAX_HEIGHT), uiConfig?.origins);
        }
    };

    /**
     * Poll the parents @see MAX_MONITOR_COUNT times every @see POLL_PARENTS_TIMER ms.
     * Clear all other polling activity before setting up a new poll, as we only need one polling chain.
     */
    const pollParents = () => {
        const timeoutId = window.setTimeout(() => {
            ++monitorCount.current;
            updateHeight();

            if (monitorCount.current < MAX_MONITOR_COUNT) {
                pollParents();
            }
        }, POLL_PARENTS_TIMER);
        timeoutIds.forEach(clearTimeout);
        timeoutIds.clear();
        timeoutIds.add(timeoutId);
    };

    /**
     * Send a reset height event to the parents. Also set the delay to true, so that the next actual height change event is sent after a delay
     * so the parent has a chance to register the reset event. This is used on page change especially reset the height each time and not overestimate the height
     * needed to display all the content on the new page.
     */
    const reset = () => {
        height.current = RESET_HEIGHT;
        alertHeightToParents(RESET_HEIGHT, uiConfig?.origins);
        setDelay(true);
        pollParents();
    };

    return {
        reset,
    };
};

const alertHeightToParents = (scrollHeight: number, brokerWebsites?: Origins) => {
    if (brokerWebsites == null) {
        return;
    }

    const event = {
        scrollHeight,
    };
    const stringifiedEvent = JSON.stringify(event);
    for (const [type, url] of Object.entries(brokerWebsites)) {
        try {
            if (url && type === AppPortalType.CHECKOUT_HOST) {
                window.parent?.postMessage(stringifiedEvent, url);
            }
        } catch {
            // do nothing
        }
    }
};
