import { lowerCase, round, startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { InvoiceSummary } from '../../type/Types';
import { Box, Grid, Typography } from '@mui/material';
import { Charge, ChargeType } from '../../apis/term';
import { styled } from '@mui/system';

interface Props {
    invoice: InvoiceSummary;
}

const InvoiceTotals = (props: Props) => {
    const invoice = props.invoice;
    const selectedTerm = useSelector((state: RootState) => state.persistedTermReducer).value;

    const BorderedBoxStyle = styled(Box)(({ theme }) => ({
        border: '1px solid',
        borderColor: theme.palette.black?.['15'],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        minWidth: '300px',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        background: theme.palette.transparentBackGround?.['100'],
    }));

    const currencyFormatter = new Intl.NumberFormat('en-nz', {
        style: 'currency',
        currency: 'NZD',
    });

    const renderFees = () => {
        return selectedTerm.feeCharges
            ?.filter((charge: Charge) => ![ChargeType.TRANSACTION_FEE, ChargeType.DEFAULT_FEE].includes(charge.type))
            .map((charge: Charge) => {
                return (
                    <Detail title={startCase(lowerCase(charge.type))} key={charge.chargeIdentifier}>
                        {currencyFormatter.format(charge.amount)}
                    </Detail>
                );
            });
    };

    return (
        <BorderedBoxStyle>
            <Grid container rowSpacing='12'>
                <Grid item sm={5} xs={12}>
                    <Typography variant='h4'>Total amount payable</Typography>
                </Grid>
                <Grid item sm={7} xs={12} sx={{ textAlign: { sm: 'right' }, mb: { xs: 1, sm: 0 } }}>
                    <Typography variant='h4'>
                        {currencyFormatter.format(selectedTerm.totalAmount + (selectedTerm.feeCharges[0]?.amount ?? 0))}
                    </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Detail title='Premium total'>{currencyFormatter.format(invoice.portfolio.premiums)}</Detail>

                {renderFees()}

                {selectedTerm.interestAmount > 0 && (
                    <Detail
                        title='Interest charges'
                        subtext={`${round(selectedTerm.flatInterestRate * 100, 2).toFixed(2)}% flat rate`}
                    >
                        {currencyFormatter.format(selectedTerm.interestAmount)}
                    </Detail>
                )}
            </Grid>
        </BorderedBoxStyle>
    );
};

const Detail = ({ title, subtext, children }: { title: string; subtext?: string; children: string | JSX.Element }) => {
    return (
        <>
            <Grid item sm={5} xs={12}>
                <Typography variant='body1'>{title}</Typography>
            </Grid>
            <Grid item sm={7} xs={12} sx={{ textAlign: { sm: 'right' }, mb: { xs: 1, sm: 0 } }}>
                <Typography variant='body1'>{children}</Typography>
                {subtext != null && (
                    <Typography variant='subtitle1' data-testid='interestRate'>
                        {subtext}
                    </Typography>
                )}
            </Grid>
        </>
    );
};

export default InvoiceTotals;
