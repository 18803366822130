import { RouteProps, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Layout from '../layout';
import { axiosAuthRequestInterceptor } from '../interceptors/auth';
import axios from 'axios';
import { handleCheckoutFlowError } from '../apis/checkoutFlowStatus';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppSelector } from '../store/reducer/Hooks';
import { RootState } from '../store/Store';

export type UnprotectedRouteProps = RouteProps & {
    children: JSX.Element;
    wider?: boolean;
    hideHeader?: boolean;
};

const LayoutWrapper = (props: UnprotectedRouteProps) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const pageWider = useAppSelector((state: RootState) => state.PageWidthReducer).value;
    const { clientLeadRequest } = useAppSelector((root) => root.persistedUnsolicitedPaymentReducer);

    useEffect(() => {
        axios.interceptors.request.use(axiosAuthRequestInterceptor, (error) => Promise.reject(error));
        axios.interceptors.response.use(
            (response) => response,
            (error) => handleCheckoutFlowError(error, navigate, authStatus)
        );
        setLoading(false);
    }, [authStatus]);

    return (
        <>
            {!loading && (
                <Layout wider={props.wider ?? pageWider} hideHeader={props.hideHeader || clientLeadRequest != null}>
                    {props.children}
                </Layout>
            )}
        </>
    );
};

export default LayoutWrapper;
