import LayoutWrapper from '../../components/LayoutWrapper';
import ReferenceDetails from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper hideHeader>
            <ReferenceDetails />
        </LayoutWrapper>
    );
}
