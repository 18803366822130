import { Box, Divider, Typography } from '@mui/material';
import { InvoiceSummary, Policy } from '../../type/Types';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { BREAKPOINT } from '../../store/reducer/BreakpointReducer';
import { grey } from '@mui/material/colors';

interface PolicyDetailsProps {
    invoice: InvoiceSummary;
}

const InvoiceDetails = (props: PolicyDetailsProps) => {
    const { invoice } = props;
    const portfolio = invoice.portfolio;
    const { up, down } = useSelector((state: RootState) => state.persistedBreakpointReducer);

    const columns: TableColumn<Policy>[] = [
        {
            name: 'Number',
            selector: (row) => row.number,
            omit: down(BREAKPOINT.md),
            width: '100px',
        },
        {
            name: 'Class',
            selector: (row) => row.classCode,
            omit: down(BREAKPOINT.md),
            width: '100px',
        },
        {
            name: 'Provider',
            selector: (row) => row.provider,
            omit: down(BREAKPOINT.md),
        },
        {
            right: true,
            name: 'Premium',
            cell: (row) => currencyFormatter.format(row.currentPolicyVersion.premiums),
            omit: down(BREAKPOINT.md),
        },
        {
            name: 'Policies',
            cell: Policies,
            omit: up(BREAKPOINT.md),
        },
    ];

    return (
        <>
            <Divider sx={{ mt: 2, mb: 1 }} />

            <DataTable
                data={portfolio.policies}
                columns={columns}
                customStyles={customStyles}
                noTableHead={down(BREAKPOINT.md)}
            />

            <Divider sx={{ mt: 1, mb: 1 }} />

            <Box sx={{ display: 'flex', gap: 3, justifyContent: 'end' }}>
                <Typography variant='subtitle2' component='p'>
                    Premiums
                </Typography>
                <Typography variant='subtitle2' component='p'>
                    {currencyFormatter.format(invoice.portfolio.premiums)}
                </Typography>
            </Box>
        </>
    );
};

const Policies = (row: Policy) => {
    return (
        <Box
            data-testid='mobile-policies'
            sx={{ display: 'flex', flexDirection: 'column', marginY: 1, gap: { xs: 1, sm: 0 } }}
        >
            <MobileRow title='Number' description={row.number} />
            <MobileRow title='Class' description={row.classCode} />
            <MobileRow title='Provider' description={row.provider} />
            <MobileRow title='Premium' description={currencyFormatter.format(row.currentPolicyVersion.premiums)} />
        </Box>
    );
};

const MobileRow = ({ title, description }: { title: string; description: string }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Typography sx={{ mb: { xs: 0.5, sm: 0 }, mr: 1 }}>{title}: </Typography>
            <Typography sx={{ fontWeight: '400' }}>{description}</Typography>
        </Box>
    );
};

const customStyles = {
    table: {
        style: {
            backgroundColor: grey['100'],
        },
    },
    headRow: {
        style: {
            border: '0px !important',
            backgroundColor: grey['100'],
        },
    },
    rows: {
        style: {
            border: '0px !important',
            '&:nth-child(odd)': {
                backgroundColor: grey['50'],
            },
        },
    },
    headCells: {
        style: {
            fontWeight: 600,
            color: grey['800'],
        },
    },
    cells: {
        style: {
            fontWeight: 400,
            color: grey['800'],
            '& div': { whiteSpace: 'break-spaces !important' },
        },
    },
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

export default InvoiceDetails;
