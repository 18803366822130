import _ from 'lodash';
import { Fragment } from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { CalculatedOn, Charge, ChargeType, PaymentFrequency, Term } from '../apis/term';
import { FeeConfig, calculateFirstInstalmentLessSetupFee } from '../functions/feeUtils';
import moment from 'moment';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

export type TermDetailsProps = {
    term: Term;
    feeConfig?: FeeConfig;
};

const TermDetails = (props: TermDetailsProps) => {
    const { term, feeConfig } = props;

    if (!feeConfig) {
        return null;
    }

    const renderFees = () => {
        return term.feeCharges
            ?.filter((charge) => charge.type !== ChargeType.TRANSACTION_FEE)
            .map((charge: Charge) => {
                return (
                    <Fragment key={charge.chargeIdentifier}>
                        <Grid item sm={8} xs={12}>
                            <Typography variant='subtitle2' component='p'>
                                {_.upperFirst(_.lowerCase(charge.type))}
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12} sx={{ textAlign: { sm: 'right' } }}>
                            <Typography variant='subtitle2' component='p'>
                                {currencyFormatter.format(charge.initialAmount)}
                            </Typography>
                        </Grid>
                    </Fragment>
                );
            });
    };

    const isFirstPaymentDayToday = moment(term.firstPaymentDate).isSame(new Date(), 'day');

    let instalementTitle;
    let instalmentAmount;
    if (term.paymentFrequency === PaymentFrequency.IN_FULL) {
        instalementTitle = 'Premium';
        instalmentAmount = term.instalmentAmount;
    } else if (isFirstPaymentDayToday) {
        instalementTitle = 'First instalment';
        instalmentAmount = calculateFirstInstalmentLessSetupFee(term);
    } else {
        instalementTitle = `${_.upperFirst(_.lowerCase(term.paymentFrequency))} instalment`;
        instalmentAmount = term.instalmentAmount;
    }

    return (
        <Paper sx={{ p: 3, mt: 3, mb: 3 }}>
            <Grid container spacing={{ sm: 2, xs: 1 }}>
                <Grid item sm={6} xs={12}>
                    <Typography variant='subtitle2' component='p'>
                        {instalementTitle}
                    </Typography>
                </Grid>

                <Grid item sm={6} xs={12} sx={{ textAlign: { sm: 'right' } }}>
                    <Typography variant='subtitle2' component='p'>
                        {currencyFormatter.format(instalmentAmount)}
                    </Typography>
                </Grid>

                {renderFees()}

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Typography variant='h2' component='p'>
                        Due Today
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={12} sx={{ textAlign: { sm: 'right' } }}>
                    <Typography variant='h2' component='p'>
                        {currencyFormatter.format(term.initialPaymentAmount + feeConfig.upfrontPaymentFee)}
                    </Typography>
                    {feeConfig.charge && (
                        <Typography sx={{ fontSize: 11 }}>
                            {feeConfig.charge.calculatedOn === CalculatedOn.PERCENT
                                ? `includes ${feeConfig.charge.chargeValue}% processing fee`
                                : `includes ${currencyFormatter.format(feeConfig.charge.initialAmount)} processing fee`}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default TermDetails;
