import { createSlice } from '@reduxjs/toolkit';

export enum InvoicePaymentMethod {
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    CREDIT_CARD = 'CREDIT_CARD',
    NOT_SET = 'NOT_SET',
}

export const selectedPaymentMethodSlice = createSlice({
    name: 'selectedPaymentMethod',
    initialState: {
        value: InvoicePaymentMethod.NOT_SET,
    },
    reducers: {
        setSelectedPaymentMethod: (state, action: SelectPaymentMethodAction) => {
            state.value = action.payload;
        },
    },
});

export interface SelectPaymentMethodAction {
    type: string;
    payload: InvoicePaymentMethod;
}

export const { setSelectedPaymentMethod } = selectedPaymentMethodSlice.actions;

export default selectedPaymentMethodSlice.reducer;
