import axios, { AxiosError } from 'axios';
import { includes } from 'lodash';
import { getRoute } from '../components/StatusGuard';
import { NavigateFunction } from 'react-router-dom';
import { AuthStatus } from '@aws-amplify/ui';

export enum CheckoutStatus {
    CREATED = 'CREATED',
    SELECT_TERM = 'SELECT_TERM',
    ID_CREATE_ACCOUNT = 'ID_CREATE_ACCOUNT',
    PAYMENT = 'PAYMENT',
    COMPLETE = 'COMPLETE',
    CONFIRM_LOAN_DETAILS = 'CONFIRM_LOAN_DETAILS',
}

export type CheckoutStatusDto = {
    checkoutStatus: CheckoutStatus;
    backwardsPaths: CheckoutStatus[];
    authRequired: boolean;
};

const clientPaymentErrorContentType: string = 'application/vnd.simfuni-client_payment_error.v1.0+json';

export const getCheckoutStatus = async (invoiceIdentifier: string): Promise<CheckoutStatusDto> => {
    const url = `${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceIdentifier}/status`;

    return await axios.get(url).then(({ data }) => data);
};

export const handleCheckoutFlowError = async (
    error: AxiosError<CheckoutStatusDto>,
    navigate: NavigateFunction,
    authStatus: AuthStatus
) => {
    if (error.response?.status === 404) {
        navigate('/not-found');
        return;
    }

    if (!includes(error.response?.headers['content-type'], clientPaymentErrorContentType)) {
        return Promise.reject(error);
    }

    const status = error.response?.data;

    if (status?.checkoutStatus === CheckoutStatus.COMPLETE) {
        if (authStatus === 'authenticated') {
            const route = getRoute(status.checkoutStatus, undefined);
            if (route) {
                navigate(route);
                return;
            }
        } else {
            navigate('/invoice-paid');
            return;
        }
    } else if (status?.checkoutStatus === CheckoutStatus.SELECT_TERM) {
        navigate('/payment-options');
        return;
    }

    return Promise.reject(error);
};
