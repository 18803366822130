import { CheckoutProviderType } from '../../apis/invoice';
import SimplifiedPayment from '../SimplifiedPayment';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import StandardPayment from '../StandardPayment';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { PaymentFrequency } from '../../apis/term';
import { Authenticator } from '@aws-amplify/ui-react';
import { LoginHeader } from '../../components/AuthHeader';
import PageLoading from '../../components/PageLoading';
import { useEffect } from 'react';
import { setSelectedTerm } from '../../store/reducer/SelectedTermReducer';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const invoice = useAppSelector((state) => state.persistedInvoiceReducer).value;
    const persistedTerm = useSelector((state: RootState) => state.persistedTermReducer).value;

    useEffect(() => {
        if (persistedTerm.paymentFrequency == null && invoice.term != null) {
            dispatch(setSelectedTerm(invoice.term));
        }
    }, [invoice]);

    if (invoice.checkoutProviderType === CheckoutProviderType.SIMPLE) {
        return <SimplifiedPayment />;
    }

    if (persistedTerm.paymentFrequency == null) {
        return <PageLoading />;
    }

    if (invoice.checkoutProviderType === CheckoutProviderType.CLIENT_INITIATED) {
        return <StandardPayment back={() => navigate(-2)} />;
    }

    if (persistedTerm.paymentFrequency === PaymentFrequency.IN_FULL) {
        return <StandardPayment />;
    }

    return (
        <Authenticator hideSignUp={true} components={{ Header: LoginHeader }}>
            <StandardPayment />
        </Authenticator>
    );
};

export default Payment;
