import { useState } from 'react';
import { Alert, Checkbox, FormControl, FormControlLabel, Link } from '@mui/material';
import { Control, Controller, ControllerRenderProps, FieldErrors, UseFormRegister } from 'react-hook-form';
import { PaymentFormFields } from '../pages/SimplifiedPayment';
import { PaymentFrequency, TermPaymentConfig } from '../apis/term';

interface AgreeTermsProps {
    term?: TermPaymentConfig;
    invoiceUuid: string;
    control: Control<PaymentFormFields>;
    register: UseFormRegister<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    firstPaymentDate?: string;
}

const AgreeTerms = (props: AgreeTermsProps) => {
    const { control, register, invoiceUuid, term, errors } = props;
    const [inputError, setInputError] = useState(false);

    const renderLabel = () => {
        if (!term) {
            return (
                <>
                    I agree to the repayment schedule above; and{' '}
                    <Link target='_blank' href={'https://simfuni.com/privacy-policy'}>
                        privacy policy
                    </Link>
                </>
            );
        }
        if (term.lending) {
            return (
                <>
                    I agree to the repayment schedule above; the terms and conditions set out in the{' '}
                    <Link target='_blank' href={`/${invoiceUuid}/contract?startDate=${props.firstPaymentDate}`}>
                        loan contract
                    </Link>{' '}
                    and{' '}
                    <Link target='_blank' href={'https://simfuni.com/privacy-policy'}>
                        privacy policy
                    </Link>
                </>
            );
        } else if (term.paymentFrequency !== PaymentFrequency.IN_FULL) {
            return (
                <>
                    I agree to the repayment schedule above; and{' '}
                    <Link target='_blank' href={'https://simfuni.com/privacy-policy'}>
                        privacy policy
                    </Link>
                </>
            );
        } else {
            return (
                <>
                    I agree to the{' '}
                    <Link target='_blank' href={'https://simfuni.com/privacy-policy'}>
                        privacy policy
                    </Link>
                </>
            );
        }
    };

    const handleConfirmation = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: ControllerRenderProps<PaymentFormFields, 'termsAccepted'>
    ) => {
        if (event.target.checked) {
            setInputError(false);
        } else {
            setInputError(true);
        }
        field.onChange(event);
    };

    return (
        <FormControl fullWidth required sx={{ mb: 2 }}>
            <Controller
                name='termsAccepted'
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 }, mb: 2 }}
                                    {...field}
                                    {...register('termsAccepted')}
                                    onChange={(e) => handleConfirmation(e, field)}
                                />
                            }
                            label={<span data-testid='agreeTermsLabel'>{renderLabel()}</span>}
                        />
                        {(inputError || errors.termsAccepted) && (
                            <Alert sx={{ mt: 1 }} severity='error' data-testid='agreeTermsError'>
                                You need to agree to the terms and conditions in order to proceed
                            </Alert>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};

export default AgreeTerms;
