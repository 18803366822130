import { FetchInvoiceAction, InvoiceSummary } from '../../type/Types';
import { createSlice } from '@reduxjs/toolkit';

export interface FetchInvoiceUuidAction {
    type: string;
    payload: string;
}

const initialState = {
    value: {} as InvoiceSummary,
    uuid: '',
};

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setAppInvoice: (state, action: FetchInvoiceAction) => {
            state.value = action.payload;
        },
        setAppInvoiceUuid: (state, action: FetchInvoiceUuidAction) => {
            state.uuid = action.payload;
        },
        resetInvoice: (state) => {
            state.value = initialState.value;
            state.uuid = '';
        },
    },
});

export const { setAppInvoice, setAppInvoiceUuid, resetInvoice } = invoiceSlice.actions;

export default invoiceSlice.reducer;
