import { Address } from '../apis/checkout';

interface FormattedAddressProps {
    address: Address;
}

const FormattedAddress = (props: FormattedAddressProps) => {
    const address = props.address;

    return (
        <>
            {address.addressLine1 && <>{address.addressLine1}</>}
            {address.addressLine2 && (
                <>
                    ,<br />
                    {address.addressLine2}
                </>
            )}
            {address.suburb && (
                <>
                    ,<br />
                    {address.suburb}
                </>
            )}
            {(address.city || address.postCode) && (
                <>
                    ,<br />
                </>
            )}
            {address.city || address.postCode}
        </>
    );
};

export default FormattedAddress;
