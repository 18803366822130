import { useEffect, useState } from 'react';
import TermDetails from '../../components/TermDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { Box, Typography } from '@mui/material';
import { BankAccount as BankAccountDataType, correctDDAuthority } from '../../apis/directDebitAuthority';
import { ChargeType, PaymentFrequency } from '../../apis/term';
import { FeeConfig, getFeeConfig } from '../../functions/feeUtils';
import { get } from 'lodash';
import BankAccountForm from '../../components/BankAccountForm';

const PaymentCorrection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = location.state as string;

    const invoice = useSelector((state: RootState) => state.persistedInvoiceReducer).value;
    const selectedTerm = useSelector((state: RootState) => state.persistedTermReducer).value;
    const [feeConfig, setFeeConfig] = useState<FeeConfig | null>(null);

    useEffect(() => {
        setFeeConfig(
            getFeeConfig(get(invoice, 'term.termPaymentMethod.paymentMethodCharges', []), ChargeType.TRANSACTION_FEE)
        );
    }, [invoice]);

    const onSubmit = (bankAccount: BankAccountDataType) =>
        correctDDAuthority(invoice.uuid, token, bankAccount).then((response) => {
            navigate('/invoice-paid');
            return response;
        });

    if (!feeConfig) {
        return null;
    }
    return (
        <Box>
            <Typography variant='h2'>Payment details</Typography>
            <Typography variant='body1'>
                {selectedTerm.paymentFrequency !== PaymentFrequency.IN_FULL
                    ? 'Provide your bank account details below to finalise your application and to authorise your recurring payments.'
                    : 'Provide your bank account details below to authorise your payment.'}
            </Typography>

            <TermDetails term={selectedTerm} feeConfig={feeConfig} />

            <Typography sx={{ marginBottom: 1 }} variant='h6' component='p'>
                Direct Debit
            </Typography>

            <BankAccountForm term={selectedTerm} onSubmit={onSubmit} feeConfig={feeConfig} />
        </Box>
    );
};

export default PaymentCorrection;
