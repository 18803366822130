import { configureStore } from '@reduxjs/toolkit';
import InvoiceReducer from './reducer/InvoiceReducer';
import SelectedTermReducer from './reducer/SelectedTermReducer';
import ClientReducer from './reducer/ClientReducer';
import UserReducer from './reducer/UserReducer';
import storage from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import BreakpointReducer from './reducer/BreakpointReducer';
import CybersourceReducer from './reducer/CybersourceReducer';
import SelectedPaymentMethodReducer from './reducer/SelectedPaymentMethodReducer';
import SellerReducer from './reducer/SellerReducer';
import PaymentPlanReducer from './reducer/PaymentPlanReducer';
import PageWidthReducer from './reducer/PageWidthReducer';
import UnsolicitedPaymentReducer from './reducer/UnsolicitedPaymentReducer';

const invoiceConfig = {
    key: 'invoice',
    storage,
};

const persistedInvoiceReducer = persistReducer(invoiceConfig, InvoiceReducer);

const userConfig = {
    key: 'user',
    storage,
};

const persistedUserReducer = persistReducer(userConfig, UserReducer);

const termConfig = {
    key: 'term',
    storage,
};

const persistedTermReducer = persistReducer(termConfig, SelectedTermReducer);

const clientConfig = {
    key: 'client',
    storage,
};

const persistedClientReducer = persistReducer(clientConfig, ClientReducer);

const sellerConfig = {
    key: 'seller',
    storage,
};

const persistedSellerReducer = persistReducer(sellerConfig, SellerReducer);

const breakpointConfig = {
    key: 'breakpoint',
    storage,
};

const persistedBreakpointReducer = persistReducer(breakpointConfig, BreakpointReducer);

const paymentMethodConfig = {
    key: 'paymentMethod',
    storage,
};

const persistedPaymentMethodReducer = persistReducer(paymentMethodConfig, SelectedPaymentMethodReducer);

const paymentPlanConfig = {
    key: 'paymentPlan',
    storage,
};

const persistedPaymentPlanReducer = persistReducer(paymentPlanConfig, PaymentPlanReducer);

const unsolicitedPaymentConfig = {
    key: 'unsolicitedPayment',
    storage,
};

const persistedUnsolicitedPaymentReducer = persistReducer(unsolicitedPaymentConfig, UnsolicitedPaymentReducer);

export const Store = configureStore({
    reducer: {
        persistedInvoiceReducer,
        persistedTermReducer,
        persistedClientReducer,
        persistedSellerReducer,
        persistedUnsolicitedPaymentReducer,
        persistedUserReducer,
        persistedBreakpointReducer,
        CybersourceReducer,
        persistedPaymentPlanReducer,
        persistedPaymentMethodReducer,
        PageWidthReducer,
    },
    middleware: [thunk],
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

export const persistor = persistStore(Store);
