import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControl,
    FormHelperText,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { includes } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
    Control,
    Controller,
    FieldErrors,
    UseFormClearErrors,
    UseFormGetValues,
    UseFormSetError,
    UseFormSetValue,
} from 'react-hook-form';
import { CardPaymentProcessor, PaymentFrequency, TermPaymentMethod } from '../apis/term';
import mcLogo from '../images/mastercard.svg';
import visaLogo from '../images/visa.svg';
import { PaymentFormFields } from '../pages/SimplifiedPayment';
import { InvoicePaymentMethod } from '../store/reducer/SelectedPaymentMethodReducer';
import BankAccount from './BankAccount';
import CybersourcePayment from './CybersourcePayment';
import WindcavePayment from './WindcavePayment';

interface PaymentMethodControlProps {
    control: Control<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    setError: UseFormSetError<PaymentFormFields>;
    setValue: UseFormSetValue<PaymentFormFields>;
    clearErrors: UseFormClearErrors<PaymentFormFields>;
    getValues: UseFormGetValues<PaymentFormFields>;
    paymentFrequency?: PaymentFrequency;
    paymentAmount?: number;
    preSubmitRef: React.MutableRefObject<{ preSubmitHelper: () => Promise<string> } | undefined>;
    paymentMethods: TermPaymentMethod[];
    isDirty: boolean;
    cardPaymentError?: string;
}

export default function PaymentMethodControl(props: Readonly<PaymentMethodControlProps>) {
    const {
        paymentFrequency,
        paymentAmount,
        paymentMethods,
        cardPaymentError,
        preSubmitRef,
        control,
        setValue,
        errors,
        setError,
        clearErrors,
        getValues,
        isDirty,
    } = props;
    const [openPaymentMethod, setOpenPaymentMethod] = useState<InvoicePaymentMethod>(InvoicePaymentMethod.NOT_SET);
    const paymentMethodTypes = useMemo(() => {
        return paymentMethods
            .map(getPaymentMethodType)
            .filter((methodControl) => methodControl != null) as PaymentMethodType[];
    }, [paymentMethods]);

    useEffect(() => {
        const defaultPaymentMethod = getValues('paymentMethod');
        if (defaultPaymentMethod !== InvoicePaymentMethod.NOT_SET) {
            setOpenPaymentMethod(defaultPaymentMethod);
        }
    }, []);

    useEffect(() => {
        if (
            isDirty &&
            openPaymentMethod &&
            !includes(
                paymentMethods.map((method) => method.paymentMethodType),
                openPaymentMethod
            ) &&
            openPaymentMethod !== InvoicePaymentMethod.NOT_SET
        ) {
            setOpenPaymentMethod(InvoicePaymentMethod.NOT_SET);
            setValue('paymentMethod', InvoicePaymentMethod.NOT_SET);
        }

        const firstPaymentMethod = paymentMethods[0].paymentMethodType;
        if (paymentMethods.length === 1 && firstPaymentMethod) {
            setValue('paymentMethod', firstPaymentMethod);
            setOpenPaymentMethod(firstPaymentMethod);
        }
    }, [paymentMethods]);

    const findDda = (paymentMethods: TermPaymentMethod[]) => {
        const paymentMethodFound = paymentMethods.find(
            (pm) => pm.paymentMethodType === InvoicePaymentMethod.DIRECT_DEBIT
        );
        return paymentMethodFound?.directDebitAuthorityNumber ?? 'NOT_FOUND';
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant='h5' component='h2' sx={{ mb: 2 }}>
                Payment method
            </Typography>

            <Box>
                {paymentMethodTypes.length === 1 ? (
                    <>
                        {paymentMethodTypes[0] === PaymentMethodType.DIRECT_DEBIT && (
                            <BankAccount
                                directDebitAuthorityNumber={findDda(paymentMethods)}
                                paymentFrequency={paymentFrequency}
                                paymentAmount={paymentAmount}
                                control={control}
                                setError={setError}
                                clearErrors={clearErrors}
                                errors={errors}
                            />
                        )}
                        {paymentMethodTypes[0] === PaymentMethodType.CYBERSOURCE && (
                            <CybersourcePayment
                                paymentFrequency={paymentFrequency}
                                control={control}
                                errors={errors}
                                getValues={getValues}
                                preSubmitRef={preSubmitRef}
                                cardPaymentError={cardPaymentError}
                            />
                        )}
                        {paymentMethodTypes[0] === PaymentMethodType.WINDCAVE && (
                            <WindcavePayment cardPaymentError={cardPaymentError} />
                        )}
                    </>
                ) : (
                    <FormControl fullWidth required sx={{ mb: 2 }}>
                        <Controller
                            name='paymentMethod'
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    id='paymentMethod'
                                    {...field}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                        if (value) {
                                            event.target.value = value;
                                            field.onChange(event);
                                            setOpenPaymentMethod(value as InvoicePaymentMethod);
                                        }
                                    }}
                                >
                                    {paymentMethodTypes.map((type) => {
                                        return (
                                            <Accordion
                                                key={type}
                                                expanded={openPaymentMethod === getInvoicePaymentMethod(type)}
                                                className='payment-method-accordion'
                                                disableGutters={true}
                                            >
                                                <AccordionSummary sx={{ m: 0, p: 0 }}>
                                                    <>
                                                        {type === PaymentMethodType.DIRECT_DEBIT && (
                                                            <DirectDebitSummary />
                                                        )}
                                                        {type === PaymentMethodType.CYBERSOURCE && <CardSummary />}
                                                        {type === PaymentMethodType.WINDCAVE && <CardSummary />}
                                                    </>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ bgcolor: grey['200'] }}>
                                                    <>
                                                        {type === PaymentMethodType.DIRECT_DEBIT && (
                                                            <BankAccount
                                                                directDebitAuthorityNumber={findDda(paymentMethods)}
                                                                paymentFrequency={paymentFrequency}
                                                                paymentAmount={paymentAmount}
                                                                control={control}
                                                                setError={setError}
                                                                clearErrors={clearErrors}
                                                                errors={errors}
                                                            />
                                                        )}
                                                        {type === PaymentMethodType.CYBERSOURCE && (
                                                            <CybersourcePayment
                                                                paymentFrequency={paymentFrequency}
                                                                control={control}
                                                                errors={errors}
                                                                getValues={getValues}
                                                                preSubmitRef={preSubmitRef}
                                                                cardPaymentError={cardPaymentError}
                                                            />
                                                        )}
                                                        {type === PaymentMethodType.WINDCAVE && (
                                                            <WindcavePayment cardPaymentError={cardPaymentError} />
                                                        )}
                                                    </>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                )}
                {errors.paymentMethod?.message && (
                    <FormHelperText data-testid='paymentMethodError' error={!!errors?.paymentMethod}>
                        {errors?.paymentMethod?.message}
                    </FormHelperText>
                )}
            </Box>
        </Box>
    );
}

enum PaymentMethodType {
    DIRECT_DEBIT,
    CYBERSOURCE,
    WINDCAVE,
}

const getInvoicePaymentMethod = (type: PaymentMethodType): InvoicePaymentMethod => {
    switch (type) {
        case PaymentMethodType.DIRECT_DEBIT:
            return InvoicePaymentMethod.DIRECT_DEBIT;
        case PaymentMethodType.CYBERSOURCE:
        case PaymentMethodType.WINDCAVE:
            return InvoicePaymentMethod.CREDIT_CARD;
        default:
            return InvoicePaymentMethod.NOT_SET;
    }
};

const getPaymentMethodType = (paymentMethod: TermPaymentMethod): PaymentMethodType | null => {
    if (paymentMethod.paymentMethodType === InvoicePaymentMethod.DIRECT_DEBIT) {
        return PaymentMethodType.DIRECT_DEBIT;
    }

    if (paymentMethod.cardPaymentProcessor === CardPaymentProcessor.CYBERSOURCE) {
        return PaymentMethodType.CYBERSOURCE;
    }
    if (paymentMethod.cardPaymentProcessor === CardPaymentProcessor.WINDCAVE) {
        return PaymentMethodType.WINDCAVE;
    }

    return null;
};

const DirectDebitSummary = () => {
    return (
        <label
            htmlFor='ddOption'
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                minHeight: '64px',
                cursor: 'pointer',
            }}
        >
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Radio id='ddOption' value={InvoicePaymentMethod.DIRECT_DEBIT} />
                <Typography>Direct debit</Typography>
            </span>
        </label>
    );
};

const CardSummary = () => {
    return (
        <label
            htmlFor='ccOption'
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                minHeight: '64px',
                cursor: 'pointer',
            }}
        >
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Radio id='ccOption' value={InvoicePaymentMethod.CREDIT_CARD} />
                <Typography>Credit card</Typography>
            </span>
            <span style={{ height: '100%', display: 'flex', marginRight: '8px' }}>
                <img src={visaLogo} alt='' width='38px' />
                <img src={mcLogo} style={{ marginLeft: 1, marginRight: 1 }} alt='' width='38px' />
            </span>
        </label>
    );
};
