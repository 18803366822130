import { useEffect, useState } from 'react';
import { PasswordField, TextField } from '@aws-amplify/ui-react';
import PasswordValidator from './PasswordValidator';
import { AccountDetails } from '../../apis/checkout';
import { Box } from '@mui/material';

export default ({ data }: { data: AccountDetails }) => {
    const [password, setPassword] = useState('');
    const [showValidation, setShowValidation] = useState(false);

    useEffect(() => {
        if (password && password.length > 0 && !showValidation) {
            setShowValidation(true);
        }
    }, [password]);

    return (
        <>
            <TextField label='Email' disabled readOnly defaultValue={data.email} />
            <PasswordField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                }}
                label='Password'
                value={password}
                required
                name='password'
            />
            {showValidation && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', lineHeight: '20px' }}>
                    <PasswordValidator password={password} message='One uppercase character' regex={uppercaseRegex} />
                    <PasswordValidator password={password} message='One lowercase character' regex={lowercaseRegex} />
                    <PasswordValidator password={password} message='One number' regex={numericRegex} />
                    <PasswordValidator
                        password={password}
                        message='One special character (e.g. !@#$%^&*)'
                        regex={specialCharRegex}
                    />
                    <PasswordValidator password={password} message='8 characters minimum' regex={minEightRegex} />
                </Box>
            )}
        </>
    );
};

const uppercaseRegex = /(?=.*[A-Z])/;
const lowercaseRegex = /(?=.*[a-z])/;
const numericRegex = /(?=.*\d)/;
const specialCharRegex = /(?=.*[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|])/;
const minEightRegex = /.{8,}$/;
