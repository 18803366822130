import { InvoicePaymentMethod } from '../store/reducer/SelectedPaymentMethodReducer';
import { PaymentResponse, submitPayment } from '../apis/payment';
import { PaymentFormFields } from '../pages/SimplifiedPayment';
import { buildPaymentRequest } from './buildPaymentRequest';
import { CardPaymentProcessor, TermPaymentMethod } from '../apis/term';

const submitPaymentRequest = (
    invoiceUuid: string,
    data: PaymentFormFields,
    allowedPaymentMethods: TermPaymentMethod[],
    preSubmitRef: React.MutableRefObject<{ preSubmitHelper: () => Promise<string> } | undefined>
): Promise<PaymentResponse> => {
    const paymentRequest = buildPaymentRequest(data);

    const mustPreSubmit = allowedPaymentMethods.some(
        (method) => method.cardPaymentProcessor === CardPaymentProcessor.CYBERSOURCE
    );
    if (data.paymentMethod === InvoicePaymentMethod.CREDIT_CARD && mustPreSubmit) {
        if (!preSubmitRef.current) {
            return Promise.reject(new Error('Invalid form state'));
        }

        return preSubmitRef.current.preSubmitHelper().then((authToken) => {
            return submitPayment(invoiceUuid, paymentRequest(authToken));
        });
    } else {
        return submitPayment(invoiceUuid, paymentRequest());
    }
};

export default submitPaymentRequest;
