import LayoutWrapper from '../../components/LayoutWrapper';
import LoadInvoiceFromReference from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper hideHeader>
            <LoadInvoiceFromReference />
        </LayoutWrapper>
    );
}
