import { CheckCircle } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { ClientLeadRequestWrapper } from '../../apis/clientLead';
import { PaymentSummary, fetchPaymentSummary } from '../../apis/payment';
import { ChargeType, PaymentFrequency } from '../../apis/term';
import { FetchWrapper } from '../../components/FetchWrapper';
import MyAccount from '../../components/MyAccount';
import PaymentDayHint from '../../components/PaymentDayHint';
import PaymentPlanDetail from '../../components/PaymentPlanDetail';
import { FeeConfig, getFeeConfig } from '../../functions/feeUtils';
import { DATE_DISPLAY_FORMAT, formatEnumValue } from '../../functions/instalmentUtils';
import { useFetch } from '../../hooks/useFetch';
import { useAppSelector } from '../../store/reducer/Hooks';
import { InvoicePaymentMethod } from '../../store/reducer/SelectedPaymentMethodReducer';
import { InvoiceSummary } from '../../type/Types';

const SimplifiedPaymentComplete = () => {
    const invoice: InvoiceSummary = useAppSelector((root) => root.persistedInvoiceReducer).value;
    const clientLeadRequest = useAppSelector((root) => root.persistedUnsolicitedPaymentReducer).clientLeadRequest;
    const [feeConfig, setFeeConfig] = useState<FeeConfig | undefined>(undefined);

    const state = useFetch(
        () =>
            fetchPaymentSummary(invoice.uuid).then((paymentSummary) => {
                const config = getFeeConfig(paymentSummary.fees, ChargeType.TRANSACTION_FEE);
                setFeeConfig(config);
                return paymentSummary;
            }),
        [invoice],
        { canFetch: () => !!invoice }
    );

    const getPaymentMessage = (paymentSummary: PaymentSummary, clientLeadRequest?: ClientLeadRequestWrapper) => {
        const inFull = paymentSummary.paymentFrequency === PaymentFrequency.IN_FULL;
        if (clientLeadRequest != null && !inFull) {
            return `We'll email more details shortly to ${clientLeadRequest.clientLead.emailAddress}. We'll update you on the outcome of your application soon.`;
        }

        const today = moment();
        const isPaymentDeferred = today.isSameOrBefore(paymentSummary.firstPaymentDate);
        switch (paymentSummary.paymentMethodType) {
            case InvoicePaymentMethod.DIRECT_DEBIT:
                if (isPaymentDeferred) {
                    return `You're all set! Your${!inFull ? ' first ' : ' '}payment will be automatically deducted from your nominated bank account on ${moment(paymentSummary.firstPaymentDate).format(DATE_DISPLAY_FORMAT)}.`;
                } else {
                    return `You're all set! Your${!inFull ? ' first ' : ' '}payment will be automatically deducted from your nominated bank account soon.`;
                }
            case InvoicePaymentMethod.CREDIT_CARD:
                if (isPaymentDeferred) {
                    return `You're all set! Your${!inFull ? ' first ' : ' '}payment will be automatically deducted from your nominated card on ${moment(paymentSummary.firstPaymentDate).format(DATE_DISPLAY_FORMAT)}.`;
                } else {
                    return `You're all set! Your payment was successful.`;
                }
        }
    };

    const BorderedBoxStyle = styled(Box)(({ theme }) => ({
        border: '1px solid',
        borderColor: theme.palette.black?.['15'],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        minWidth: '300px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        background: theme.palette.transparentBackGround?.['100'],
    }));

    const SuccessComponent = useCallback(
        ({ data }: { data: PaymentSummary }) => {
            if (!feeConfig) {
                return null;
            }

            return (
                <>
                    <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
                            <CheckCircle color='success' sx={{ fontSize: 40 }} />
                            <Typography variant='h3' component='h1' sx={{ ml: 1 }}>
                                Complete
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ textAlign: 'center', mt: 3, mb: 4 }} data-testid='successMessage'>
                        {getPaymentMessage(data, clientLeadRequest)}
                    </Typography>

                    <BorderedBoxStyle>
                        <Typography variant='h4' component='h2' sx={{ mb: 1 }}>
                            Payment Summary
                        </Typography>
                        <PaymentPlanDetail paymentSummary={data} feeConfig={feeConfig} />

                        <Grid container rowSpacing='12'>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <Typography variant='body1'>Payment method</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant='body1'>{formatEnumValue(data.paymentMethodType)}</Typography>
                                <Typography variant='subtitle1'>{data.paymentMethodSignature}</Typography>
                            </Grid>
                        </Grid>

                        <PaymentDayHint
                            firstPaymentDate={data.firstPaymentDate}
                            paymentFrequency={data.paymentFrequency}
                        />
                    </BorderedBoxStyle>

                    <MyAccount paymentFrequency={data.paymentFrequency} />
                </>
            );
        },
        [invoice, feeConfig, clientLeadRequest]
    );

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
};

export default SimplifiedPaymentComplete;
