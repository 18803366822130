import { useFetch } from '../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { fetchAccountDetails } from '../../apis/checkout';
import { FetchWrapper } from '../../components/FetchWrapper';
import { Hub } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadedAuthenticator from './LoadedAuthenticator';

export default () => {
    const invoiceUuid = useSelector((state: RootState) => state.persistedInvoiceReducer).uuid;
    const accountDetailState = useFetch(() => fetchAccountDetails(invoiceUuid), [invoiceUuid], {
        canFetch: () => !!invoiceUuid,
    });
    const navigate = useNavigate();

    useEffect(() => {
        Hub.listen('auth', (data) => {
            if (data.payload.event === 'signIn') {
                navigate('/summary');
            }
        });
    }, []);

    return <FetchWrapper state={accountDetailState} SuccessComponent={LoadedAuthenticator} />;
};
