import { Box, Link } from '@mui/material';
import PageHeader from './PageHeader';
import styles from '../style/app.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import hintHelper from './hintHelper.gif';
import { useEffect, useState } from 'react';
import { lowerCase } from 'lodash';
import { useAuthenticator } from '@aws-amplify/ui-react';

interface Props {
    children: React.ReactNode;
    wider?: boolean;
    hideHeader?: boolean;
}

const backgroundColorClassName = 'with-background';

const Layout = (props: Props) => {
    const { integratedView } = useSelector((state: RootState) => state.persistedSellerReducer);
    const [showHint, setShowHint] = useState(false);
    const { signOut } = useAuthenticator((context) => [context.user]);

    const bodyStyle = {
        ...(props.wider ? { maxWidth: 862 } : {}),
        ...(integratedView ? { minHeight: 0 } : {}),
    };

    useEffect(() => {
        document.onmouseup = handleHintText;
    }, []);

    useEffect(() => {
        if (integratedView) {
            document.body.classList.remove(backgroundColorClassName);
        } else {
            document.body.classList.add(backgroundColorClassName);
        }
    }, [integratedView]);

    const handleHintText = () => {
        const text = document.getSelection();
        const hintText = 'power';
        setShowHint(lowerCase(text as unknown as string) === hintText);
    };

    return (
        <Box className={!integratedView ? styles.sim_layout_background : ''}>
            {!props.hideHeader && (
                <Box className={styles.simfumi_layout_header}>
                    <PageHeader />
                </Box>
            )}
            <Box className={styles.sim_layout_body} sx={bodyStyle}>
                <Box className={styles.sim_layout_content}>{props.children}</Box>
            </Box>
            <Box className={styles.sim_layout_footer} sx={{ position: 'relative' }}>
                {showHint && (
                    <Box sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
                        <img src={hintHelper} alt='Show hint' />
                    </Box>
                )}
                Powered By Simfuni Ltd.{' '}
                {process.env.REACT_APP_ENABLE_SIGN_OUT === 'true' && <Link onClick={signOut}>Log out</Link>}
                <br />
                Copyright © 2024. All rights reserved.
            </Box>
        </Box>
    );
};
export default Layout;
