import { Box, Button, InputAdornment, InputLabel, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setUnsolicitedPaymentAmount } from '../../store/reducer/UnsolicitedPaymentReducer';
import { useNavigate } from 'react-router-dom';

export default function PaymentDetails() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { paymentAmount: amountFromRedux } = useAppSelector((root) => root.persistedUnsolicitedPaymentReducer);
    const [paymentAmount, setPaymentAmount] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (amountFromRedux) {
            setPaymentAmount(amountFromRedux.toString());
        }
    }, [amountFromRedux]);

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setError(undefined);

        if (!paymentAmount || Number(paymentAmount) <= 0) {
            setError('Please enter a valid payment amount');
            return;
        }

        dispatch(setUnsolicitedPaymentAmount(Number(paymentAmount)));
        navigate('/client-payment/personal-details');
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Box>
                    <Typography variant='h5' component='h1' mb={1}>
                        Payment details
                    </Typography>
                    <Typography variant='caption'>
                        Enter the amount you'd like to pay. You can usually find this on your invoice.
                    </Typography>
                </Box>
                <Box>
                    <InputLabel sx={{ mb: 1 }} htmlFor='payment_amount' error={!!error}>
                        Payment amount
                    </InputLabel>
                    <CovertNumberTextField
                        type='number'
                        id='payment_amount'
                        fullWidth
                        value={paymentAmount ?? ('' as unknown)}
                        onChange={(e) => setPaymentAmount(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                        }}
                        error={!!error}
                        helperText={error}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button sx={{ width: '100%' }} variant='text' onClick={() => navigate('/pay')}>
                        Back
                    </Button>
                    <Button sx={{ width: '100%' }} type='submit' onClick={handleSubmit} variant='contained'>
                        Next
                    </Button>
                </Box>
            </Box>
        </form>
    );
}

const CovertNumberTextField = styled(TextField)({
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
});
