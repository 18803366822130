import { Typography } from '@mui/material';

const NotFound = () => {
    return (
        <>
            <Typography variant='h2'>No outstanding invoice.</Typography>
            <Typography variant='body1'>
                No outstanding invoice was found.
                <br />
            </Typography>
        </>
    );
};

export default NotFound;
