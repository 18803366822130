import { SetUserAction } from '../../type/Types';
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: null as null | unknown,
    },
    reducers: {
        setUser: (state, action: SetUserAction) => {
            state.value = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
