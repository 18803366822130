import { Authenticator } from '@aws-amplify/ui-react';
import { CheckoutStatus } from '../../apis/checkoutFlowStatus';
import { LoginHeader } from '../../components/AuthHeader';
import LayoutWrapper from '../../components/LayoutWrapper';
import StatusGuard from '../../components/StatusGuard';
import PaymentSummary from '.';

export default function RouteElement() {
    return (
        <LayoutWrapper>
            <StatusGuard status={CheckoutStatus.CONFIRM_LOAN_DETAILS} authRequired route={'/summary'}>
                <Authenticator hideSignUp={true} components={{ Header: LoginHeader }}>
                    <PaymentSummary />
                </Authenticator>
            </StatusGuard>
        </LayoutWrapper>
    );
}
