import { Lock } from '@mui/icons-material';
import { Alert, Box, Typography } from '@mui/material';

type Props = {
    cardPaymentError?: string;
};

export default function WindcavePayment({ cardPaymentError }: Readonly<Props>) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 3 }}>
            {cardPaymentError && <Alert severity='error'>{cardPaymentError}</Alert>}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', maxWidth: 500 }}>
                <Lock color='action' />
                <Typography>You will be redirected to a secure page to input your payment details.</Typography>
            </Box>
        </Box>
    );
}
