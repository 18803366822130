import axios, { AxiosResponse } from 'axios';

export type BankAccount = {
    accountHolder: string;
    bankAccountNumber: string;
    confirmAuthority: boolean;
};

export const correctDDAuthority = async (
    invoiceUuid: string,
    correctionToken: string,
    bankAccount: BankAccount
): Promise<AxiosResponse<void>> => {
    const url = `${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceUuid}/correct-direct-debit-authorisation`;

    return await axios.put(url, bankAccount, { params: { 'correction-token': correctionToken } });
};
