import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchInvoiceStatus, InvoiceStatus } from '../../apis/checkout';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { InvoiceSummary } from '../../type/Types';
import { styled } from '@mui/system';
import useTokenization from '../../hooks/useTokenization';

const MAX_POLLS = 30;

const PaymentProcessing = () => {
    const navigate = useNavigate();
    const invoice: InvoiceSummary = useSelector((state: RootState) => state.persistedInvoiceReducer).value;
    const pollingCount = useRef(0);
    const timeoutId = useRef(0);
    const [error, setError] = useState(false);
    useTokenization();

    const checkInvoiceStatus = () => {
        ++pollingCount.current;

        fetchInvoiceStatus(invoice.uuid)
            .then((status) => {
                if (status === InvoiceStatus.PAID) {
                    navigate('/payment-complete');
                } else if (status === InvoiceStatus.DECLINED) {
                    navigate('/payment');
                } else if (pollingCount.current >= MAX_POLLS) {
                    setError(true);
                } else {
                    timeoutId.current = window.setTimeout(() => checkInvoiceStatus(), 2000);
                }
            })
            .catch(() => {
                setError(true);
            });
    };

    useEffect(() => {
        timeoutId.current = window.setTimeout(() => checkInvoiceStatus(), 2000);
        return () => {
            try {
                window.clearTimeout(timeoutId.current);
            } catch {
                // do nothing
            }
        };
    }, []);

    if (error) {
        return <Alert severity='error'>There was an error making your payment, please contact Simfuni.</Alert>;
    }

    return (
        <>
            <Typography data-testid='loading' style={{ textAlign: 'center', paddingTop: '50px' }}>
                <SimfuniCircularProgress size={80} />
            </Typography>
            <Typography variant='h2' sx={{ margin: '50px', textAlign: 'center' }}>
                Processing Payment
            </Typography>
        </>
    );
};

export default PaymentProcessing;

const SimfuniCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary?.dark,
}));
