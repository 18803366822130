import { fetchInvoiceUuidByReference } from '../../apis/checkout';
import { getRecaptchaToken } from '../../apis/recaptcha';

export default async function getInvoiceUuid(reference: string): Promise<string | undefined> {
    try {
        const recaptchaToken = await getRecaptchaToken();
        return await fetchInvoiceUuidByReference(reference, recaptchaToken);
    } catch {
        return undefined;
    }
}
